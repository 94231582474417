import { Chip } from 'app/components/chip/chip.types';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { FileService } from 'app/services/file/file.service';
import { Filter, Todo } from './todo.types';
import { GdrivesService } from 'app/services/gdrives/gdrives.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { EventManager } from 'app/services/events/events.service';
import {
  LocalStorageService,
  USER_INFO_KEY,
} from 'app/services/local-storage/local-storage.service';
import {
  People,
  Workflow,
  PrimeFileNode,
} from 'app/types';
import { AuthService } from 'app/services/auth/auth.service';


@Component({
  selector: 'app-todo',
  templateUrl: './todo.component.html',
  styleUrl: './todo.component.scss',
  providers: [ConfirmationService],
})
export class TodoComponent implements OnDestroy, OnInit {
  /** READONLY VALUE */
  readonly ALL: string = Filter.ALL;
  readonly DEFAULT_DISPLAY_MIN_ELEMENTS: number = 3;
  readonly EXTENSION_HEARTBEAT: string = 'PRESENCEDOC_EXTENSION_HEARTBEAT';
  readonly TABLES_SHORT_CONTAINER: string = 'tables-short-container';
  readonly TO_APPROVE: string = Filter.TO_APPROVE;
  readonly TO_APPROVE_PARAM: string = 'waiting';
  readonly TO_UPDATE: string = Filter.TO_UPDATE;
  readonly I_REQUESTED: string = Filter.I_REQUESTED;
  readonly MANAGE_LABELS: string = Filter.MANAGE_LABELS;

  /** Class properties */
  currentUser: People | null = null;
  filesToApproveSubscription: Subscription | undefined;
  filesToUpdateSubscription: Subscription | undefined;
  todo: Todo = {
    breadcrumbIcon: { icon: '', routerLink: '' },
    breadcrumbPath: [],
    chips: {},
    dynamicClass: '',
    filesIRequested: [],
    filesToApprove: [],
    filesToUpdate: [],
    filesToManage: [],
    filteredOn: Filter.ALL,
    isFooterDisplayed: false,
    workflows: new Map<string, Workflow>(),
  };
  selectedFiles: PrimeFileNode[] = [];

  constructor(
    private fileService: FileService,
    private gdriveService: GdrivesService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    /** Get current user */
    this.currentUser =
      this.localStorageService.getItem<People>(USER_INFO_KEY);

    if (!this.currentUser) {
      const data = await lastValueFrom(this.authService.getUser());

      this.currentUser = data.user;
      this.localStorageService.setItem<People>(
        USER_INFO_KEY,
        this.currentUser,
      );
    }
    /** Fill todo object */
    this.todo = {
      chips: {
        /*[Filter.ALL]: {
          label: 'All',
          icon: '',
          isActive: false,
        },*/
        [Filter.TO_APPROVE]: {
          label: 'To approve',
          icon: 'pi-check',
          isActive: true,
          isLoading: false,
        },
        [Filter.TO_UPDATE]: {
          label: 'To update',
          icon: '',
          isActive: false,
          isLoading: false,
        },
        [Filter.I_REQUESTED]: {
          label: 'Approval status',
          icon: '',
          isActive: false,
          isLoading: false,
        },
        [Filter.MANAGE_LABELS]: {
          label: 'Manage labels',
          icon: '',
          isActive: false,
          isLoading: false,
        }
      },
      dynamicClass: '',
      filesIRequested: [],
      filesToApprove: [],
      filesToUpdate: [],
      filesToManage: [],
      breadcrumbIcon: { icon: 'pi pi-home', routerLink: '/todo' },
      breadcrumbPath: [{ label: 'To do' }],
      isFooterDisplayed: false,
      filteredOn: Filter.TO_APPROVE,
      workflows: new Map<string, Workflow>(),
    };

    EventManager.on('tableRefresh', () => {
      this.filesToApproveSubscription = this.fileService
        .getWorkflowFiles(this.TO_APPROVE_PARAM)
        .subscribe((response) => {
          this.todo.filesToApprove = [];
          EventManager.emit('clearTableSelection');
          this.todo.isFooterDisplayed = false;
          response.workflows.forEach((workflow) => {
            this.todo.filesToApprove.push(workflow.file as PrimeFileNode);
          });
        });
    });

    /** Get files to approve */
    this.todo.chips[Filter.TO_APPROVE].isLoading = true;
    this.filesToApproveSubscription = this.fileService
      .getWorkflowFiles(this.TO_APPROVE_PARAM)
      .subscribe((response) => {
        response.workflows.forEach((workflow) => {
          this.todo.filesToApprove.push(workflow.file as PrimeFileNode);
        });
        if (this.todo.filesToApprove.length > 0) {
          this.todo.chips[Filter.TO_APPROVE].badge =
            this.todo.filesToApprove.length;
          this.updateTheAllBadge();
        }
        this.todo.chips[Filter.TO_APPROVE].isLoading = false;
      });

    /** Get files pending requested by me */
    this.todo.chips[Filter.I_REQUESTED].isLoading = true;
    this.fileService.requestedByMe().subscribe((response) => {
      this.todo.filesIRequested = response.files.map((file) => {
        return file as PrimeFileNode;
      });
      response.files.map((file) => {
        if (file?.workflows?.length)
          this.todo.workflows.set(
            file.id as string,
            file.workflows[0],
          );
      });
      if (this.todo.filesIRequested.length > 0) {
        this.todo.chips[Filter.I_REQUESTED].badge =
          this.todo.filesIRequested.length;
        this.updateTheAllBadge();
      }
      this.todo.chips[Filter.I_REQUESTED].isLoading = false;
    });

    /** Get files expiring soon */
    this.todo.chips[Filter.MANAGE_LABELS].isLoading = true;
    this.fileService.getExpiringFiles().subscribe(({files}) => {
      this.todo.filesToManage = files as PrimeFileNode[];
      if (this.todo.filesToManage.length > 0) {
        this.todo.chips[Filter.MANAGE_LABELS].badge =
          this.todo.filesToManage.length;
        this.updateTheAllBadge();
      }
      this.todo.chips[Filter.MANAGE_LABELS].isLoading = false;
    });

    /** Get files to update */
    this.todo.chips[Filter.TO_UPDATE].isLoading = true;
    this.filesToUpdateSubscription = this.fileService
      .getFlaggedDocuments()
      .subscribe((response) => {
        response.files.forEach((file) => {
          this.todo.filesToUpdate.push(file as PrimeFileNode);
        });
        if (this.todo.filesToUpdate.length > 0) {
          this.todo.chips[Filter.TO_UPDATE].badge =
            this.todo.filesToUpdate.length;
          this.updateTheAllBadge();
        }
        this.todo.chips[Filter.TO_UPDATE].isLoading = false;
      });

    /** Listen when files are selected in a table */
    EventManager.on('tableSelectedFiles', () => {
      this.selectedFiles = this.gdriveService.getTableSelectedFile();
      this.todo.isFooterDisplayed = this.selectedFiles.length > 0;
      this.todo.dynamicClass = this.todo.isFooterDisplayed
        ? this.TABLES_SHORT_CONTAINER
        : '';
    });
  }

  updateTheAllBadge() {
    /*this.todo.chips[Filter.ALL].badge = Object.entries(this.todo.chips).reduce(
      (acc, [key, value]) => {
        if (key !== Filter.ALL) {
          return acc + (value.badge ?? 0);
        }
        return acc;
      },
      0,
    );*/
  }

  ngOnDestroy() {
    this.filesToApproveSubscription?.unsubscribe();
    this.filesToUpdateSubscription?.unsubscribe();
  }

  updateChipAndFilter(chip: Chip): void {
    Object.values(this.todo.chips).forEach((value) => {
      value.isActive = false;
      value.icon = '';
    });
    chip.isActive = true;
    chip.icon = 'pi-check';
    this.todo.filteredOn = chip.label as Filter;
    EventManager.emit('clearTableSelection');
  }
}
