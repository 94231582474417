<app-loading-component [isLoading]="isLoading">
  <div class="step-container">
    <p-steps
      [model]="items"
      [(activeIndex)]="activeIndex"
      [readonly]="true"
      class="stepper"
    ></p-steps>

    @if (activeIndex === 0) {
      <div class="step-content">
        <div class="subheading">
          To be able to install Overlayer capabilities on your company's Shared
          Drives
        </div>
        <div class="main-heading">
          We need you to create a dedicated tech user on your domain & a custom
          role
        </div>
        <div class="description">
          We will use this dedicated user to create & set Google labels,
          automate sharing, etc.
        </div>
        <div class="description">
          Check the full list of features as well as the Services privileges
          this dedicated user will have
          <p-button
            label="here"
            [link]="true"
            (click)="redirectTo(servicesLink)"
          />
        </div>
        <p-button
          label="Login with G-Workspace admin"
          severity="contrast"
          (onClick)="checkUserRoleAndRedirect()"
        ></p-button>
      </div>
    }

    @if (activeIndex === 1) {
      <div class="step-content">
        <div class="subheading">
          We're going to create a dedicated tech user called
          {{ clientEmail }} with the custom role “Overlayer”
        </div>
        <div class="main-heading">
          We need you to connect to Overlayer with this user using this 1-time
          password
        </div>
        @if (!isServiceAccountInCreation()) {
          <div class="description">
            <label for="email">Email: </label>
            <input type="email" pInputText [(ngModel)]="clientEmail" />

            <label class="password" for="password">Password: </label>
            <p-password
              [(ngModel)]="clientPassword"
              [disabled]="true"
              placeholder="**********"
            ></p-password>

            <p-button
              icon="pi pi-copy"
              [rounded]="true"
              [text]="true"
              (click)="copyPassword()"
              class="copy-button"
            ></p-button>
          </div>
        } @else {
          <p>
            It appears that a dedicated account was already created, login with
            the previously created account using the previously given password.
          </p>
        }

        @if (!isAccountCreationInProgress) {
          <p-button
            label="Login to Overlayer using {{ clientEmail }}"
            severity="contrast"
            (onClick)="createUser()"
            class="login-button"
            [disabled]="!pwdCopied && !isServiceAccountInCreation()"
          ></p-button>
        }

        @if (!pwdCopied && !isServiceAccountInCreation) {
          <p style="color: red">
            You need to copy the login info (email + password) above to be able
            to go to the next step.
          </p>
        }

        @if (isAccountCreationInProgress) {
          <div>
            <p-progressSpinner
              ariaLabel="loading"
              class="top-drive-spinner"
            ></p-progressSpinner>
          </div>
        }
      </div>
    }

    @if (activeIndex === 2) {
      <div class="step-content">
        <div class="subheading">
          We've been able to create Google labels that will be used to
          supercharge your Shared drives
        </div>
        <div class="main-heading">
          Next, let's decide on which shared drives, Overlayer should be usable.
        </div>
        <div class="description">
          We strongly encourage you to set Overlayer on all shared drives. You
          will be able to change the selection at any moment in the future.
        </div>
        <div class="description custon-margin-description">
          {{ clientEmail }} will be added as a “Contributor”.

          <p-button
            label="Learn more"
            [link]="true"
            (click)="redirectTo(contributorAccessLink)"
            class="custom-padding-link"
          />
        </div>
        @if (!isInstallingOnSharedDrives) {
          <div>
            <p-button
              label="Let's install Overlayer on ALL shared drives"
              severity="contrast"
              (onClick)="installOnAllSharedDrive()"
            ></p-button>
          </div>
          <div>
            <p-button
              label="Nah, I prefer to choose a list of shared drives"
              [link]="true"
              (click)="toggleDialog('installOnSharedDrives')"
            />
          </div>
        }
        @if (isInstallingOnSharedDrives) {
          <p-progressSpinner ariaLabel="loading" class="top-drive-spinner">
          </p-progressSpinner>
        }
      </div>
    }
    @if (activeIndex === 3) {
      <div class="step-content">
        <div class="subheading">
          This is the last step to unlock Overlayer<br />
          FOR ALL your company USERS
        </div>
        <div class="main-heading">
          Install Overlayer's chrome extension directly on all company's chrome
          users
        </div>
        <div class="description">
          It allows all Chrome users to use Overlayer without any installation
          on their part, improving document management from day 1.
        </div>
        <p-button
          label="Follow the step by step guide to install Overlayer extension on users' Chrome browser"
          severity="contrast"
          (onClick)="installOverlayerExtension()"
        ></p-button>
      </div>
    }
  </div>
  <p-dialog
    header="Select Name of Client"
    [(visible)]="openDialogs.createDedicatedAccount"
    [modal]="true"
    [draggable]="false"
    [style]="{ width: '40%' }"
  >
    <input
      type="text"
      pInputText
      [(ngModel)]="clientLabel"
      [style]="{ width: '100%' }"
    />

    <p-footer>
      <p-button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-secondary"
        severity="contrast"
        (click)="toggleDialog('createDedicatedAccount', false)"
      ></p-button>
      <p-button
        label="Confirm"
        icon="pi pi-check"
        (click)="confirmDedicatedAccountNameSelection()"
        severity="contrast"
        [disabled]="clientLabel === ''"
      ></p-button>
    </p-footer>
  </p-dialog>
  <p-dialog
    header="Install Overlayer on selected Shared drives"
    [(visible)]="openDialogs.installOnSharedDrives"
    [modal]="true"
    [draggable]="false"
    [style]="{ width: '70%', height: '80%' }"
  >
    <div>
      Select/Unselect the shared drives where Overlayer should be installed
    </div>

    <div class="custom-margin">
      <p-multiSelect
        [options]="authorizedDrives"
        [(ngModel)]="selectedAuthorizedDrives"
        [loading]="isAuthorizedDrivesLoading"
        (onClear)="clearFilter()"
        placeholder="Select drive"
        optionLabel="name"
        display="chip"
        [overlayVisible]="isLoaded"
        [ngModelOptions]="{ standalone: true }"
      >
      </p-multiSelect>
    </div>
    @if (!isInstallingOnSharedDrives) {
      <p-footer class="custom-footer">
        <p-button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-secondary"
          severity="contrast"
          (click)="toggleDialog('installOnSharedDrives', false)"
        ></p-button>
        <p-button
          label="Confirm"
          icon="pi pi-check"
          (click)="installOnSharedDrives()"
          severity="contrast"
        ></p-button>
      </p-footer>
    }

    @if (isInstallingOnSharedDrives) {
      <p-progressSpinner
        ariaLabel="loading"
        class="spinner-container"
      ></p-progressSpinner>
    }
  </p-dialog>
</app-loading-component>
