import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import {
  DialogState,
  LikeButtonData,
} from 'app/pages/side-panel/side-panel.types';
import {
  Rating,
  User,
} from 'app/types';

@Component({
  selector: 'side-panel-footer',
  templateUrl: './side-panel-footer.component.html',
  styleUrl: './side-panel-footer.component.scss'
})
export class SidePanelFooterComponent {
  @Input() splitItems: MenuItem[] = [];
  @Input() likeButtonData?: LikeButtonData = undefined;
  @Input() ratings?: Rating[] = [];
  @Input() user?: User = undefined;
  @Input() loading: boolean = false;
  @Output() addRatingEvent = new EventEmitter();

  readonly DialogState = DialogState;

  ratedByTheUser: boolean = false;

  isRatedByTheUser() {
    return this.ratings?.find((rating) => { return (rating.rated_by.primary_email === this.user?.primary_email) }) ? true : false;
  }
}
