export class EventManager {
  static on<K extends keyof WindowEventMap>(
    type: K,
    listener: (this: Window, ev: WindowEventMap[K]) => unknown,
    options?: boolean | AddEventListenerOptions,
  ): (this: Window, ev: WindowEventMap[K]) => unknown;
  static on(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
  ): EventListenerOrEventListenerObject;
  static on<K extends keyof WindowEventMap>(
    event: K | string,
    callback:
      | EventListenerOrEventListenerObject
      | ((this: Window, ev: WindowEventMap[K]) => unknown),
  ) {
    window.addEventListener(
      event,
      callback as EventListenerOrEventListenerObject,
      false,
    );
    return callback;
  }

  static emit(event: string, data?: unknown) {
    window.dispatchEvent(new CustomEvent(event, { detail: data }));
  }

  static off<K extends keyof WindowEventMap>(
    type: K,
    listener: (this: Window, ev: WindowEventMap[K]) => unknown,
    options?: boolean | EventListenerOptions,
  ): void;
  static off(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions,
  ): void;
  static off<K extends keyof WindowEventMap>(
    event: K | string,
    callback:
      | EventListenerOrEventListenerObject
      | ((this: Window, ev: WindowEventMap[K]) => unknown),
  ) {
    window.removeEventListener(
      event,
      callback as EventListenerOrEventListenerObject,
    );
  }
}
