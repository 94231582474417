@if (authorizedRoute) {
  <div class="single-page-main-container">
    <app-top-bar></app-top-bar>
    <div class="body-container">
      <app-side-bar-navigation></app-side-bar-navigation>
      <div class="body-column">
        <router-outlet />
      </div>
    </div>
  </div>
} @else {
  <router-outlet />
}
<app-tracker></app-tracker>
<p-toast
  [breakpoints]="{ '920px': { width: '100%', right: '0', left: '0' } }"
></p-toast>
