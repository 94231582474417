<p-dialog
  [header]="header"
  [modal]="true"
  [(visible)]="visible"
  [draggable]="false"
  position="bottom"
  [style]="{ width: '100%' }"
  (onHide)="hideDialogCallback.emit(DialogState.NONE)"
  class="dropdown-scroll"
>
<app-loading-component
    [isLoading]="isLoading"
  >
    <ng-container
      *ngComponentOutlet="dialog; injector: injector"
    />
  </app-loading-component>
</p-dialog>
