<div class="apply-rating">
  <span>How would you rate this document?</span>
  <p-rating
    stars="5"
    [(ngModel)]="userRating"
    [cancel]="false"
  ></p-rating>
  <p-button
    label="Apply rating"
    (mousedown)="applyRating(userRating)"
  />
</div>
