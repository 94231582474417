import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  File,
  LabelFieldOptionType,
  LabelFieldType,
  LabelType,
  VerifiedVersion,
  Version,
} from 'app/types';
import { isFileVerified } from 'app/utils/utils';

@Component({
  selector: 'side-panel-versioning',
  templateUrl: './side-panel-versioning.component.html',
  styleUrl: './side-panel-versioning.component.scss'
})
export class SidePanelVersioningComponent {
  @Input() fileVerifiedHistory?: VerifiedVersion = undefined;
  @Input() file?: File = undefined;
  @Input() hasRights: boolean = false;
  @Input() loading: boolean = false;
  @Output() createNewVersion = new EventEmitter();

  readonly VerifiedLabels: LabelType[] = [
    LabelType.VERIFIED,
    LabelType.VERIFIED_HAS_BEEN_EDITED,
    LabelType.VERIFIED_EXPIRE_SOON,
  ];

  isDraft(): boolean {
    return this.fileVerifiedHistory?.history?.versions.find((version) => version.file?.id === this.file?.id && version.is_draft) ? true : false;
  }

  isFileVerified() {
    return isFileVerified(this.file as File);
  }

  isLatestVersion(): boolean {
    return this.fileVerifiedHistory?.history?.latest_version?.file?.id === this.file?.id;
  }

  ongoingDraft(): string {
    const  currentFileVersion: Version | undefined = this.fileVerifiedHistory?.history?.versions.find((version) => version.file?.id === this.file?.id);

    const draftFile = this.fileVerifiedHistory?.history?.versions?.find((version) => {
      return (
        version.is_draft &&
        version.file?.id !== this.file?.id &&
        (currentFileVersion?.version ?? 0) < (version.version ?? 0) ||
        version.version === null
      )
    });
    return draftFile?.file?.id ?? '';
  }

  openFileInDrive(fileId?: string) {
    window.open(`https://docs.google.com/open?id=${fileId}`, '_blank')?.focus();
  }
}
