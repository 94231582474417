import {
  Component,
  Inject,
} from '@angular/core';
import { AngularComponentInputs } from 'app/types';

@Component({
  selector: 'app-rating-dialogs',
  templateUrl: './rating-dialogs.component.html',
  styleUrl: './rating-dialogs.component.scss'
})
export class RatingDialogsComponent {
  userRating: number = 0;
  applyRating: Function = () => {};

  constructor(
    @Inject('INPUTS') private inputs: AngularComponentInputs,
    @Inject('OUTPUTS') public outputs: AngularComponentInputs
  ) {
    this.userRating = this.inputs.rating;
    this.applyRating = this.outputs.applyRating;
  }
}
