@if (loading) {
  <div class="skeleton-tags">
    <p-skeleton height="40px" class="skeleton-tags" styleClass="mb-2" />
  </div>
} @else {
  <div class="tags-container">
    <span class="span-header">Properties</span>
    <div class="tags-list">
      @if (language) {
        <p-tag
          class="tag"
          icon="pi pi-language"
          value="{{ language }}"
        />
      }
      @if (confidentiality) {
        <p-tag
          class="tag"
          icon="pi pi-lock"
          value="{{ confidentiality }}"
        />
      }
      @if (documentType) {
        <p-tag
          class="tag"
          icon="pi pi-briefcase"
          value="{{ documentType }}"
        />
      }
      @for (tag of tags; track tag) {
        <p-tag
          class="tag"
          icon="pi pi-tag"
          value="{{ tag.tag?.label }}"
        />
      }

      @if (!language && !confidentiality && !documentType && !tags?.length) {
        <div class="no-property">
          <i class="pi pi-tags"></i>
          <span>There's no property yet.</span>
        </div>
      }
      @if (!locked && hasRights) {
        <div
          class="text-icon-button"
          (mousedown)="editPropertiesCallback.emit(DialogState.PROPERTIES)"
        >
          <i class="pi pi-plus-circle"></i>
          <span>Edit properties</span>
        </div>
      }
    </div>
  </div>
}
