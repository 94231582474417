import {
  Component,
  Inject,
} from '@angular/core';
import { AngularComponentInputs } from 'app/types';

@Component({
  selector: 'app-flag-dialog',
  templateUrl: './flag-dialog.component.html',
  styleUrl: './flag-dialog.component.scss'
})
export class FlagDialogComponent {
  flagReason: string = '';
  onCancel: Function = () => {};
  onFlag: Function = () => {};

  constructor(@Inject('OUTPUTS') public outputs: AngularComponentInputs) {
    this.onCancel = outputs.cancelFlagDialog;
    this.onFlag = outputs.flagDocument;
  }
}
