import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableMistral } from 'app/types/ai.types';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { PyroneQAChatParams } from './pyrone.types';

@Injectable({
  providedIn: 'root',
})
export class PyroneService {
  constructor(private httpClient: HttpClient) {}

  chatQA(options: PyroneQAChatParams) {
    const body = {
      text: options.text,
    };

    return this.httpClient.post(`${environment.pyroneURL}/chat/qa`, body, {
      withCredentials: options.authorized,
    }) as Observable<ObservableMistral>;
  }
}
