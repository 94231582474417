<textarea
  rows="5"
  pInputTextarea
  [(ngModel)]="descriptionInput"
  class="description-textarea"
>
</textarea>
<div class="description-actions">
  <p-button
    label="Cancel"
    severity="secondary"
    (mousedown)="onCancel()"
  />
  <p-button
    label="Save"
    [disabled]="!descriptionInput || descriptionInput === description"
    (mousedown)="onSave(descriptionInput)"
  />
</div>
