<div class="side-bar">
  <div>
    <div
      class="side-bar-button {{ activeTab === '/verified' ? 'active' : '' }}"
      routerLink="/verified"
    >
      <div>
        <i class="pi pi-check-circle side-bar-todo-icon"></i>
        <span>Verified docs</span>
      </div>
    </div>
    <div
      class="side-bar-button {{ activeTab === '/todo' ? 'active' : '' }}"
      routerLink="/todo"
    >
      <div>
        <i class="pi pi-clipboard side-bar-todo-icon"></i>
        <span>To do</span>
      </div>
    </div>
    <div class="side-bar-todo-shared-drives">
      <span
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 16px;
        "
        >Shared drives ({{ driveEntities.length || '...' }})
        @if (loadingDrives) {
          <p-progressSpinner ariaLabel="loading" class="top-drive-spinner" />
        }
      </span>
      <p-tree
        [value]="driveEntities"
        selectionMode="single"
        [filter]="true"
        (onNodeSelect)="nodeSelect($event)"
        [lazy]="true"
        (onNodeExpand)="onNodeExpand($event)"
      >
        <ng-template let-node pTemplate="empty">
          @if (!loadingDrives) {
            <p>No shared drive found</p>
          }
        </ng-template>

        <ng-template let-node pTemplate="default">
          <div class="tree-row">
            <span class="tree-node-label" pTooltip="{{ node.tooltip }}">{{
              node.name
            }}</span>
            <div class="tree-row">
              @if (node.loadingFolders) {
                <i
                  ariaLabel="loading"
                  class="pi pi-spin pi-spinner"
                  pTooltip="Loading content..."
                ></i>
              } @else if (node.scanning) {
                <i
                  ariaLabel="scanning"
                  class="pi pi-spin pi-sync"
                  pTooltip="Updating Shared Drive files"
                ></i>
              }
            </div>
          </div>
        </ng-template>
      </p-tree>
      @if (!loadingDrives && driveEntities.length === 0) {
        <p>No shared drive found</p>
      }
    </div>
  </div>
  <div>
    <p-button
      label="Logout"
      [link]="true"
      class="disconect-button"
      icon="pi pi-sign-out"
      (mousedown)="logout()"
    ></p-button>
  </div>
</div>
