<div class="apply-properties">
  <p-autoComplete
    [suggestions]="searchedTags"
    optionLabel="label"
    [multiple]="true"
    (completeMethod)="searchTags($event)"
    [(ngModel)]="tagsInput"
    [virtualScroll]="true"
    [virtualScrollItemSize]="30"
    placeholder="Select tags"
  >
    <ng-template let-tag pTemplate="item">
      @if (tag.id !== -1) {
        <div class="tag-selector">
          <i class="pi pi-tags"></i>
          <span>{{ tag.label }}</span>
        </div>
      } @else {
        <div class="tag-selector blue">
          <i class="pi pi-plus-circle"></i>
          <span>{{ tag.label }}</span>
        </div>
      }
    </ng-template>
  </p-autoComplete>
  <p-dropdown
    [options]="confidentialityOptions"
    [(ngModel)]="confidentialitiesInput"
    placeholder="Confidentiality"
    class="dropdown-width"
  />
  <p-dropdown
    [options]="documentTypeOptions"
    [(ngModel)]="documentTypeInput"
    placeholder="Document type"
    class="dropdown-width"
  />
  <p-dropdown
    [options]="LanguageOptions"
    [(ngModel)]="languageInput"
    placeholder="Language"
    class="dropdown-width"
  />
  <diV class="properties-buttons">
    <p-button
      label="Cancel"
      severity="secondary"
      (mousedown)="cancelPropertiesDialog()"
    />
    <p-button
      label="Save changes"
      (mousedown)="savePropertiesDialog(
        tagsInput,
        confidentialitiesInput,
        documentTypeInput,
        languageInput
      )"
    />
  </diV>
</div>
