@if (loading) {
  <p-skeleton height="26px" class="skeleton-label" styleClass="mb-2" />
} @else if (labels && labels.length) {
  <div class="labels-container">
    @for (option of options; track option) {
      @if (LabelsMap.get(option.type)?.displayable) {
        <p-tag
          class="tag"
          [class]="LabelsMap.get(option.type)?.class"
          (click)="LabelsMap.get(option.type)?.onClick($event, op)"
        >
          <div class="template-tag">
            <app-icon
              [value]="LabelsMap.get(option.type)?.icon"
              [icon]="LabelsMap.get(option.type)?.isPrimeNgIcon"
            />
            <span>{{LabelsMap.get(option.type)?.value}}</span>
          </div>
        </p-tag>
        <p-overlayPanel #op class="custom-overlay-panel">
          <div class="title">Flag reason</div>
          <div>
            {{ getFlaggedMessage() }}
          </div>
        </p-overlayPanel>
      }
    }
  </div>
}
