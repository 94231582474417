import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from 'app/services/auth/auth.service';
import { getLoginURI, isLoginURI } from 'app/services/api-settings';

export const authGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  try {
    await authService.isUserAuthenticated();
    return true;
  } catch (e) {
    // do not redirect if we are already in login URI
    if (!isLoginURI()) {
      authService.logout();
      window.location.href = getLoginURI({
        redirectBackToCurrentPage: true,
      });
    }
    return false;
  }
};
