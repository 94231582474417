import { Component, Input } from '@angular/core';
import { People } from 'app/types';

@Component({
  selector: 'side-panel-contributors',
  templateUrl: './side-panel-contributors.component.html',
  styleUrl: './side-panel-contributors.component.scss',
})
export class SidePanelContributorsComponent {
  @Input() creator?: People = undefined;
  @Input() contributors?: People[] = [];

  extractNameFromPeople(people: People): string {
    if (people.fullname) return people.fullname;
    if (!people.email) return '';

    return people.email
      .split('@')[0]
      .split('.')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
      .join(' ');
  }
}
