import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LikeResponse } from 'app/types/like.types';
import { TrinityService } from 'app/services/trinity/trinity.service';

@Injectable({
  providedIn: 'root',
})
export class LikeService {
  private readonly endpoint: string = '/like';
  constructor(private trinityService: TrinityService) {}

  like(file_id: string): Observable<LikeResponse> {
    const res = this.trinityService.post<LikeResponse>(
      `${this.endpoint}/${file_id}`,
      {
        authorized: true,
      },
    );

    return res;
  }

  unlike(file_id: string): Observable<LikeResponse> {
    const res = this.trinityService.delete<LikeResponse>(
      `${this.endpoint}/${file_id}`,
      {
        authorized: true,
      },
    );

    return res;
  }
}
