import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  setItem<T>(key: string, value: string | T): void {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }

    localStorage.setItem(key, value as string);
  }

  getItem<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }
    return JSON.parse(item);
  }

  deleteItem(key: string): void {
    localStorage.removeItem(key);
  }

  mergeItem<T>(key: string, value: T): void {
    const item = this.getItem<T>(key);
    this.setItem(key, { ...item, ...value });
  }
}

export const AUTH_INFO = 'authInfo';
export const USER_INFO_KEY = 'userInfo';
