import { Component, OnDestroy, OnInit } from '@angular/core';
import { GdrivesService } from 'app/services/gdrives/gdrives.service';
import { ReplaySubject, Subscription, lastValueFrom, takeUntil } from 'rxjs';
import { PyroneService } from 'app/services/pyrone/pyrone.service';
import {
  FileFlagType,
  DEFAULT_GOOGLE_AVATAR_URI,
  BulkResponse,
  PrimeFileNode,
  File,
  FileDatamart,
  Tag,
  Source,
  LabelFieldOptionType
} from 'app/types';
import {
  AskMistral,
  VerifiedDocs,
} from 'app/pages/verified-docs/verified.types';
import {
  FileIcon,
  FolderContentFilters,
} from 'app/pages/folder-content/folder-content.types';
import {
  isGoogleContainer,
  pushIfNotFound,
  shortMimeType,
  labelsFieldOptionTypeToText,
  fileHasAnyOfThisLabels,
  textToLabelsFieldOptionType
} from 'app/utils/utils';
import { EventManager } from 'app/services/events/events.service';
import { ToastService } from 'app/services/toast/toast.service';

@Component({
  selector: 'app-verified-docs',
  templateUrl: './verified-docs.component.html',
  styleUrl: './verified-docs.component.scss',
})
export class VerifiedDocsComponent implements OnInit, OnDestroy {
  /** READONLY VALUE */
  readonly DEFAULT_AVATAR_URI: string = DEFAULT_GOOGLE_AVATAR_URI;
  readonly TABLES_SHORT_CONTAINER: string = 'tables-short-container';
  readonly MAX_FETCHING_ITERATIONS: number = 3;

  /** Class properties */
  askMistral: AskMistral = {
    askMistralAnswer: '',
    askMistralSources: [],
    askMistralQuery: '',
    isAskMistralLoading: false,
    isDisplayed: false,
    isLoading: false,
  };
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  filters: FolderContentFilters = {
    contributors: { values: [], selected: [] },
    formats: { values: [], selected: [] },
    labels: { values: [], selected: [] },
    tags: { values: [], selected: [] },
  };
  selectedFiles: PrimeFileNode[] = [];
  verifiedDocs: VerifiedDocs = {
    areFilesLoading: false,
    breadcrumbIcon: { icon: '', routerLink: '' },
    breadcrumbPath: [],
    displayedDocuments: [],
    dynamicClass: '',
    isFooterDisplayed: false,
    verifiedDocs: [],
  };
  verifiedFilesSubscription$: Subscription | undefined;
  currentIteration: number = 0;

  constructor(
    private gdriveService: GdrivesService,
    private pyroneService: PyroneService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.askMistral = {
      askMistralAnswer: '',
      askMistralSources: [],
      askMistralQuery: '',
      isAskMistralLoading: false,
      isDisplayed: false,
      isLoading: false,
    };

    this.verifiedDocs = {
      areFilesLoading: false,
      breadcrumbIcon: { icon: 'pi pi-home', routerLink: '/todo' },
      breadcrumbPath: [{ label: 'Verified Docs' }],
      displayedDocuments: [],
      dynamicClass: '',
      isFooterDisplayed: false,
      verifiedDocs: [],
    };

    this.getVerifiedFiles();

    EventManager.on('tableRefreshContent', ((event: CustomEvent) => {
      const datamart = event.detail.datamart.processed as PrimeFileNode[];
      const tags = event.detail.tags as BulkResponse<File, string>;

      this.verifiedDocs.displayedDocuments =
        this.verifiedDocs.displayedDocuments.map((item: PrimeFileNode) => {
          if (tags) {
            const newTagsItem = tags.processed.find(
              (newItem) => newItem.id === item.id,
            );
            if (newTagsItem?.id) {
              item.tags = Object.assign(
                item.tags ?? {},
                newTagsItem.tags ?? {},
              ) as Tag[];
            }
          }

          if (datamart) {
            const newDataMartItem = datamart.find(
              (newItem) => newItem.id === item.id,
            );

            if (newDataMartItem?.id) {
              item.datamart = Object.assign(
                item.datamart ?? {},
                newDataMartItem.datamart ?? {},
              ) as FileDatamart;
            }
          }

          return item;
        });
    }) as EventListener);

    EventManager.on('tableSelectedFiles', () => {
      this.selectedFiles = this.gdriveService.getTableSelectedFile();
      this.verifiedDocs.isFooterDisplayed = this.selectedFiles.length > 0;
      this.verifiedDocs.dynamicClass = this.verifiedDocs.isFooterDisplayed
        ? this.TABLES_SHORT_CONTAINER
        : '';
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getVerifiedFiles(nextPageToken?: string) {
    this.verifiedDocs.areFilesLoading = true;
    this.gdriveService
      .getDriveVerifiedFiles(FileFlagType.VERIFIED, 20, nextPageToken)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          this.verifiedDocs.verifiedDocs = [
            ...this.verifiedDocs.verifiedDocs,
            ...(data.files as any), // eslint-disable-line
          ];
          this.verifiedDocs.displayedDocuments = [
            ...this.verifiedDocs.displayedDocuments,
            ...(data.files as any), // eslint-disable-line
          ];
          data.files.forEach((file) => {
            this.populateFilters(file as PrimeFileNode);
          });
          nextPageToken = data.next_page_token;
        },
        complete: () => {
          if (nextPageToken && this.currentIteration < this.MAX_FETCHING_ITERATIONS) {
            this.currentIteration++;
            return this.getVerifiedFiles(nextPageToken);
          } else {
            this.verifiedDocs.areFilesLoading = false;
          }
        },
      });
  }

  populateFilters(file: PrimeFileNode) {
    if (isGoogleContainer(file)) return;

    if (file.labels) {
      for (const label of file.labels) {
        for (const option of label.selected_options) {
          pushIfNotFound(
            this.filters.labels.values,
            option.type,
            (a) => a === option.type,
          );
        }
      }
    }

    if (file.tags) {
      for (const tag of file.tags) {
        pushIfNotFound(
          this.filters.tags.values,
          tag.tag,
          (a) => a?.id === tag.tag?.id,
        );
      }
    }

    if (file.mime_type) {
      const entry: FileIcon = {
        shortMimetype: shortMimeType(file.mime_type),
        mimetype: file.mime_type,
        iconLink: file.icon_link,
      };

      pushIfNotFound(
        this.filters.formats.values,
        entry,
        (a) => a.mimetype === entry.mimetype,
      );
    }

    const contributors = file.datamart?.contributors;

    if (contributors) {
      contributors.forEach((contributor) => {
        pushIfNotFound(
          this.filters.contributors.values,
          contributor,
          (a) => a.email === contributor.email,
        );
      });
    }

    const creator = file.datamart?.created_by;

    if (creator) {
      pushIfNotFound(
        this.filters.contributors.values,
        creator,
        (a) => a.email === creator.email,
      );
    }
  }

  updateFilter(type: string, e: any) /* eslint-disable-line */ {
    (this.filters as any)[type].selected = e.value; // eslint-disable-line
    this.applyFilters();
  }

  applyFilters() {
    this.verifiedDocs.displayedDocuments =
      this.verifiedDocs.verifiedDocs.filter((file) => {
        if (
          this.filters.contributors.selected.length &&
          !this.filters.contributors.selected.find((contributor) => {
            const createdByEmail = file.datamart?.created_by?.email;
            const lastModifiedByEmail = file.last_modified_by?.email;
            const contributorsEmails =
              file.datamart?.contributors?.map(
                (contributor) => contributor.email,
              ) || [];

            return (
              contributor.email === createdByEmail ||
              contributorsEmails.includes(contributor.email) ||
              contributor.email === lastModifiedByEmail
            );
          })
        ) {
          return false;
        }

        if (
          this.filters.formats.selected.length &&
          !this.filters.formats.selected.find(
            (format) => format.mimetype === file.mime_type,
          )
        ) {
          return false;
        }

        if (
          this.filters.labels.selected.length &&
          !fileHasAnyOfThisLabels(file, textToLabelsFieldOptionType(this.filters.labels.selected))
        ) {
          return false;
        }

        if (
          this.filters.tags.selected.length &&
          !this.filters.tags.selected.find((tag) =>
            file.tags?.find((t) => t.tag?.id === tag.id),
          )
        ) {
          return false;
        }

        return true;
      });
  }

  clearFilter(type: string) {
    (this.filters as any)[type].selected = []; // eslint-disable-line
  }

  labelOptionsToText(options: LabelFieldOptionType[]) {
    return labelsFieldOptionTypeToText(options);
  }

  toggleFooterAction(): void {
    this.selectedFiles = this.gdriveService.getTableSelectedFile();
    this.verifiedDocs.isFooterDisplayed = this.selectedFiles.length > 0;
    //this.bodyClass = this.displayFooter ? 'reducer' : '';
  }

  showDialog(): void {
    this.askMistral.isDisplayed = true;
  }

  getMistralAnswer() {
    this.askMistral.isLoading = true;
    const data = lastValueFrom(
      this.pyroneService.chatQA({
        text: this.askMistral.askMistralQuery,
        authorized: true,
      }),
    );
    const bulletPointRegex = /(\n|^)([•*+-]|\d+\.)\s*/g;
    data
      .then((response) => {
        this.askMistral.askMistralAnswer = response.answer.replace(
          bulletPointRegex,
          '<br><br>$1$2',
        );
        this.askMistral.askMistralSources = [];
        this.askMistral.askMistralSources = response.sources.map(
          (source) => source,
        );
        this.askMistral.isLoading = false;
      })
      .catch(() => {
        this.askMistral.isLoading = false;
        this.toastService.error({
          summary: 'Ask AI',
          detail: 'An error occurred. Please try again later.',
        });
      });
  }

  getDocUrl(source: Source): string {
    return `https://docs.google.com/open?id=${source.google_file_id}`;
  }
}
