import { Component } from '@angular/core';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrl: './onboarding.component.scss',
})
export class OnboardingComponent {
  showSetup: boolean = false;

  showSetupComponent() {
    this.showSetup = true;
  }
}
