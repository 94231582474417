<ng-template #googleLoginButton>
  <div class="google-button" (mousedown)="login()">
    <img
      src="https://user-images.githubusercontent.com/1770056/58111071-c2941c80-7bbe-11e9-8cac-1c3202dffb26.png"
      class="google-logo"
      alt="google-logo"
    />
    <span>Login with Google</span>
  </div>
</ng-template>

<div class="main-container">
  <app-loading-component
    [isLoading]="loginState === 'unknown' || loginState === 'validating'"
  >
    <ng-template *ngTemplateOutlet="googleLoginButton"> </ng-template>

    <div loading-footer>
      @if (waitingForSidePanelPopup) {
        <ng-template *ngTemplateOutlet="googleLoginButton"> </ng-template>
      }
    </div>
  </app-loading-component>
</div>
