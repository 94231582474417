import {
  Component,
  EventEmitter,
  Inject,
  Output,
} from '@angular/core';
import {
  DOC_TYPES,
  CONFIDENTIALITIES,
  LANGUAGES,
  Tag,
  AngularComponentInputs,
} from 'app/types';
import { DialogState } from 'app/pages/side-panel/side-panel.types';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';

@Component({
  selector: 'app-properties-dialog',
  templateUrl: './properties-dialog.component.html',
  styleUrl: './properties-dialog.component.scss'
})
export class PropertiesDialogComponent {
  @Output() closeDialog = new EventEmitter();

  readonly newTagId: number = -1;
  readonly confidentialityOptions = CONFIDENTIALITIES;
  readonly documentTypeOptions = DOC_TYPES;
  readonly LanguageOptions = LANGUAGES;
  readonly DialogState = DialogState;

  cancelPropertiesDialog: Function = () => {};
  savePropertiesDialog: Function = () => {};

  tagOptions: Tag[] = [];
  searchedTags: Tag[] = [];
  tagsInput: Tag[] = [];
  confidentialitiesInput?: string = undefined;
  documentTypeInput?: string = undefined;
  languageInput?: string = undefined;

  constructor(
    @Inject('INPUTS') private inputs: AngularComponentInputs,
    @Inject('OUTPUTS') public outputs: AngularComponentInputs
  ) {
    this.tagOptions = inputs.tags;
    this.tagsInput = inputs.fileTags;
    this.confidentialitiesInput = this.upperCaseFirstLetter(inputs.fileConfidentiality);
    this.documentTypeInput = this.upperCaseFirstLetter(inputs.fileType);
    this.languageInput = this.upperCaseFirstLetter(inputs.fileLanguage);
    this.cancelPropertiesDialog = outputs.cancelPropertiesDialog;
    this.savePropertiesDialog = outputs.savePropertiesDialog;
  }

  upperCaseFirstLetter(str: string) {
    if (!str) return '';
    return str?.charAt(0).toUpperCase() + str.slice(1) || '';
  }

  async searchTags(event: AutoCompleteCompleteEvent) {
    this.searchedTags = [];

    // Check if the tag name is not already in the list
    const tagExists = this.tagOptions.filter(
      (tag) => tag?.label.toLowerCase() === event?.query.toLocaleLowerCase(),
    );

    this.searchedTags = this.tagOptions.filter((tag) => {
      return tag?.label.toLowerCase().includes(event.query.toLowerCase());
    });

    if (tagExists.length === 0) {
      // Add the tag to the beginning of the list if it doesn't exist without deleting the other tags
      this.searchedTags.unshift({ id: this.newTagId, label: event.query });
    }
  }
}
