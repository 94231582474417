import { Component, OnInit } from '@angular/core';
import { Settings, UpdateSettingsOptions } from './settings.types';
import { ClientService } from 'app/services/client/client.service';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';
import { ToastService } from 'app/services/toast/toast.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent implements OnInit {
  settings: Settings = {};

  constructor(
    private clientService: ClientService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.clientService.getClient().subscribe((response) => {
      this.settings.client = response.client;
    });
  }

  onFilePermissionsConfidentialityChange(event: InputSwitchChangeEvent) {
    this.settings.client!.apply_file_permissions_from_confidentiality =
      event.checked;
    this.updateSettings({
      success: {
        what: 'File Permissions',
        how: event.checked ? 'Successfully enabled' : 'Successfully disabled',
      },
      error: {
        what: 'File Permissions',
        how: event.checked ? 'Failed to enable' : 'Failed to disable',
      },
    });
  }

  updateSettings(options: UpdateSettingsOptions) {
    this.clientService
      .modifyClient({
        ...this.settings.client,
      })
      .subscribe({
        next: (response) => {
          this.settings.client = response.client;
          this.toastService.clear();
          this.toastService.success({
            summary: options.success.what,
            detail: options.success.how,
          });
        },
        error: () => {
          this.toastService.clear();
          this.toastService.error({
            summary: options.error.what,
            detail: options.error.how,
          });
        },
      });
  }
}
