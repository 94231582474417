import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastOptions } from './toast.types';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  error(options: ToastOptions) {
    this.messageService.add({ severity: 'error', ...options });
  }

  success(options: ToastOptions) {
    this.messageService.add({ severity: 'success', ...options });
  }

  contrast(options: ToastOptions) {
    this.messageService.add({ severity: 'contrast', ...options });
  }

  warn(options: ToastOptions) {
    this.messageService.add({ severity: 'warn', ...options });
  }

  info(options: ToastOptions) {
    this.messageService.add({ severity: 'info', ...options });
  }

  clear() {
    this.messageService.clear();
  }
}
