import {
  Component,
  Inject,
} from '@angular/core';
import { VersioningOptions } from 'app/pages/side-panel/side-panel.types';
import { AngularComponentInputs } from 'app/types';

@Component({
  selector: 'app-versioning-dialog',
  templateUrl: './versioning-dialog.component.html',
  styleUrl: './versioning-dialog.component.scss'
})
export class VersioningDialogComponent {
  readonly VersioningOptions = VersioningOptions;

  dropdownOptions: VersioningOptions[] = [
    VersioningOptions.DRAFT,
    VersioningOptions.DRAFT_FROM_FILE,
  ]
  dropdownInput: VersioningOptions = VersioningOptions.DRAFT;
  fileUrlInput: string = '';

  createDraft: Function = () => {};

  constructor(@Inject('OUTPUTS') public outputs: AngularComponentInputs) {
    this.createDraft = this.outputs.createDraft;
  }
}
