@if (loading) {
  <div class="skeleton-versioning">
    <p-skeleton height="40px" class="skeleton-versioning" styleClass="mb-2" />
  </div>
}
@else if (fileVerifiedHistory) {
  <div class="versioning-container">
    <span class="span-header">Version history</span>
    <div class="add-versioning-container">
      @if (isLatestVersion() && !ongoingDraft()) {
        <div class="versioning-no-newer-file">
          <i class="pi pi-verified"></i>
          <span>There's no newer version of that file.</span>
        </div>
      }

      @if (
        isLatestVersion() &&
        isFileVerified() &&
        fileVerifiedHistory.history.versions.length > 1
        ) {
        @for (version of fileVerifiedHistory.history.versions; track version) {
          @if (!version.is_draft) {
            <div class="history-row">
              <div class="history-gap">
              <i class="pi pi-file-check"></i>
              <span
                class="ellipsis-name text-link"
                (mousedown)="openFileInDrive(version?.file?.id)"
              >{{ version?.file?.datamart?.name }}</span>
              @if (version.file.id === file?.id) {
                <p-tag class="new-version-tag" value="Current" />
              } @else {
                <p-tag
                  class="verified"
                  value="V{{ version?.version }}"
                />
              }
              </div>
              <span>{{ version?.verified_at | date }}</span>
            </div>
          }
        }
      }

      @if (!isLatestVersion() && !isDraft()) {
        <div class="warning-draft" (mousedown)="openFileInDrive(fileVerifiedHistory.history.latest_version?.file?.id)">
          <i class="pi pi-exclamation-triangle"></i>
          <span>There's a newer version of this file</span>
          <i class="pi pi-external-link"></i>
        </div>
      }

      @else if (ongoingDraft() && !isDraft()) {
        <div
          class="warning-draft"
          (mousedown)="openFileInDrive(ongoingDraft())"
        >
          <i class="pi pi-exclamation-triangle"></i>
          <span>There's an ongoing draft</span>
          <i class="pi pi-external-link"></i>
        </div>
      }

      @if (isDraft()) {
        <div class="history-row">
          <div class="history-gap">
            <i class="pi pi-file-check"></i>
            <span
              class="ellipsis-name text-link"
              (mousedown)="openFileInDrive(fileVerifiedHistory.history.latest_version?.file?.id)"
            >
              {{ fileVerifiedHistory.history.latest_version?.file?.datamart?.name }}
            </span>
            <p-tag class="verified" value="Verified" />
          </div>
          <span>{{ fileVerifiedHistory.history.latest_version?.verified_at | date }}</span>
        </div>
      }

      @if (isFileVerified() && hasRights) {
        <div class="text-icon-button" (mousedown)="createNewVersion.emit()">
          <i class="pi pi-plus-circle"></i>
          <span>Add a version</span>
        </div>
      }
    </div>
  </div>
}
