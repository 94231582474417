export class PasswordUtils {
  private static _pattern: RegExp = /[a-zA-Z0-9_\-+.]/;

  private static _getRandomByte(): number {
    if (window.crypto && window.crypto.getRandomValues) {
      const result = new Uint8Array(1);
      window.crypto.getRandomValues(result);
      return result[0];
    } else {
      // Fallback for older browsers
      return Math.floor(Math.random() * 256);
    }
  }

  public static generatePassword(length: number): string {
    const characters =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_+-.';
    let password = '';

    for (let i = 0; i < length; i++) {
      let randomByte = this._getRandomByte();
      let char = String.fromCharCode(randomByte);

      while (!this._pattern.test(char)) {
        randomByte = this._getRandomByte();
        char = String.fromCharCode(randomByte);
      }

      password += characters.charAt(randomByte % characters.length);
    }

    return password;
  }
}
