import {
  User,
  Domain
} from 'app/types';

export enum LabelType {
  VERIFIED = 'verified',
  VERIFIED_HAS_BEEN_EDITED = 'verified_has_been_edited',
  VERIFIED_EXPIRE_SOON = 'verified_expire_soon',
  APPROVAL_STATUS_PENDING = 'approval_status_pending',
  APPROVAL_STATUS_REJECTED = 'approval_status_rejected',
  TO_UPDATE_OUTDATED = 'to_update_outdated',
  FLAGGED = 'flagged',
}

export enum LabelFieldType {
  STATUS = 'status',
  VERIFICATION_PROCESS_STATUS = 'verification_process_status',
  NEEDS_UPDATE = 'needs_update',
  DISTRIBUTION_STATUS = 'distribution',
  CONTRIBUTORS = 'contributors',
  REQUESTERS = 'requesters',
  APPROVERS = 'approvers',
  DISTRIBUTORS = 'distributors',

  /** The file confidentiality field. */
  FILE_CONFIDENTIALITY = 'file_confidentiality',

  /** Default Google fields not handled by Overlayer */
  CUSTOM = 'custom',
}

export enum LabelType {
  CUSTOM = 'custom',
  OVERLAYER = 'overlayer',
  CONFIDENTIALITY = 'confidentiality',
}

export enum LabelFieldOptionType {
  /** Status field options. */
  STATUS_VERIFIED = 'status_verified',
  STATUS_HAS_BEEN_EDITED = 'status_has_been_edited',
  STATUS_VERIFICATION_EXPIRES_SOON = 'status_verification_expires_soon',
  STATUS_VERIFICATION_OUTDATED = 'status_verification_outdated',
  STATUS_VERIFIED_EXPIRED = 'status_verified_expired',
  STATUS_VERSION_DRAFT = 'status_version_draft',
  STATUS_ARCHIVED = 'status_archived',

  /** Verification  options. */
  VERIFICATION_PROCESS_PENDING = 'verification_process_pending',
  VERIFICATION_PROCESS_APPROVED = 'verification_process_approved',
  VERIFICATION_PROCESS_REJECTED = 'verification_process_rejected',
  VERIFICATION_PROCESS_TO_RENEW = 'verification_process_to_renew',
  VERIFICATION_PROCESS_PENDING_EXTRA_STEP = 'verification_process_pending_extra_step',
  VERIFICATION_PROCESS_REJECTED_EXTRA_STEP = 'verification_process_rejected_extra_step',
  VERIFICATION_PROCESS_PENDING_FINAL_STEP = 'verification_process_pending_final_step',
  VERIFICATION_PROCESS_REJECTED_FINAL_STEP = 'verification_process_rejected_final_step',

  /** Needs update options */
  NEEDS_UPDATE_FLAGGED = 'needs_update_flagged',

  /** Distribution status options */
  DISTRIBUTION_STATUS_ONGOING = 'distribution_status_ongoing',
  DISTRIBUTION_STATUS_FINISHED = 'distribution_status_finished',
  DISTRIBUTION_STATUS_STOPPED = 'distribution_status_stopped',

  /** File confidentiality level */
  FILE_CONFIDENTIALITY_PUBLIC = 'file_confidentiality_public',
  FILE_CONFIDENTIALITY_INTERNAL = 'file_confidentiality_internal',
  FILE_CONFIDENTIALITY_CONFIDENTIAL = 'file_confidentiality_confidential',
  FILE_CONFIDENTIALITY_SECRET = 'file_confidentiality_secret',

  /** Custom field, used for CustomProperties */
  CUSTOM = 'custom',
}

export interface LabelFieldOption {
  /** The ID of the option. */
  id: string;

  /** The option_id of the option. */
  option_id: string;

  /** The name of the option. */
  name: string;

  /** The description of the option. */
  description: string;

  /** The type of the option. */
  type: LabelFieldOptionType;
}

export interface LabelField {
  /** The ID of the field. */
  id: number;

  /** The field_id of the field. */
  field_id: string;

  /** The name of the field. */
  name: string;

  /** The type of the field */
  type: LabelFieldType;

  /** The options of the field */
  options: LabelFieldOption[]

  /** The creation date of the field */
  created_at: Date;

  /** The last update date of the field */
  updated_at: Date;
}

export interface FileLabel {
  /** The ID of the file label. */
  id: number;

  /** The reason of the file label */
  reason: string;

  /** The user who labeled the file */
  labeled_by: User;

  /** The label of the file */
  label: Label;

  /** The label field of the file */
  label_field: LabelField;

  /** The selected options of the file */
  selected_options: LabelFieldOption[];

  /** The creation date of the file label */
  created_at: Date;

  /** The last update date of the file label */
  updated_at: Date;
}

export interface Label {
  /** The ID of the label. */
  id: string;

  /** The name of the label. */
  name: string;

  /** The description of the label. */
  description: string;

  /** The type of the label */
  type: LabelType;

  /** The fields of the label */
  fields: LabelField[];

  /** The domain the label is associated to */
  domain: Domain;
}
