import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import {
  LocalStorageService,
  USER_INFO_KEY,
} from 'app/services/local-storage/local-storage.service';
import { AuthService } from 'app/services/auth/auth.service';
import { lastValueFrom } from 'rxjs';
import { User } from 'app/types/user.types';
@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html',
  styleUrl: './tracker.component.scss',
})
export class TrackerComponent implements OnInit {
  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    let user = this.localStorageService.getItem<User>(USER_INFO_KEY);
    if (!user) {
      const data = await lastValueFrom(this.authService.getUser());

      user = data.user;
      this.localStorageService.setItem<User>(USER_INFO_KEY, user);
    }

    if (environment.production) {
      if (environment.googleTagManagerID) {
        const googletagmanager = document.createElement('script');
        googletagmanager.type = 'text/javascript';
        googletagmanager.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleTagManagerID}`;
        document.head.appendChild(googletagmanager);
        const gtagscript = document.createElement('script');
        gtagscript.type = 'text/javascript';
        gtagscript.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${environment.googleTagManagerID}');
      `;
        document.head.appendChild(gtagscript);
      }

      if (environment.heapID) {
        const heap = document.createElement('script');
        heap.type = 'text/javascript';
        heap.text = `
        window.heapReadyCb=window.heapReadyCb||[],window.heap=window.heap||[],heap.load=function(e,t){window.heap.envId=e,window.heap.clientConfig=t=t||{},window.heap.clientConfig.shouldFetchServerConfig=!1;var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="https://cdn.us.heap-api.com/config/"+e+"/heap_config.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(a,r);var n=["init","startTracking","stopTracking","track","resetIdentity","identify","getSessionId","getUserId","getIdentity","addUserProperties","addEventProperties","removeEventProperty","clearEventProperties","addAccountProperties","addAdapter","addTransformer","addTransformerFn","onReady","addPageviewProperties","removePageviewProperty","clearPageviewProperties","trackPageview"],i=function(e){return function(){var t=Array.prototype.slice.call(arguments,0);window.heapReadyCb.push({name:e,fn:function(){heap[e]&&heap[e].apply(heap,t)}})}};for(var p=0;p<n.length;p++)heap[n[p]]=i(n[p])};
        heap.load("${environment.heapID}", { enableSecureCookies: true });
        heap.addUserProperties({'email': '${user?.primary_email}'});
        heap.identify('${user?.id}');
        console.log('Heap loaded with user=${user?.primary_email},${user?.id}');
      `;
        document.head.appendChild(heap);
      }

      if (environment.smartlookID) {
        const smartlook = document.createElement('script');
        smartlook.type = 'text/javascript';
        smartlook.text = `
        window.smartlook ||
          (function (d) {
            var o = (smartlook = function () {
              o.api.push(arguments);
            }),
              h = d.getElementsByTagName("head")[0];
            var c = d.createElement("script");
            o.api = new Array();
            c.async = true;
            c.type = "text/javascript";
            c.charset = "utf-8";
            c.src = "https://web-sdk.smartlook.com/recorder.js";
            h.appendChild(c);
          })(document);
        smartlook("init", "${environment.smartlookID}", { region: "eu" });
      `;
        document.head.appendChild(smartlook);
      }

      if (environment.crispID) {
        const crisp = document.createElement('script');
        crisp.type = 'text/javascript';
        crisp.text = `
            window.$crisp = [];
      window.CRISP_WEBSITE_ID = '${environment.crispID}';
      (function () {
        d = document;
        s = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = 1;
        d.getElementsByTagName('head')[0].appendChild(s);
      })();
      $crisp.push(["set", "user:email", "${user?.primary_email}"]);
      $crisp.push(["set", "session:data", ["google_domain", "${user?.domain.name}"]]);
      $crisp.push(["set", "session:data", ["user_roles", "${user?.roles}"]]);
      $crisp.push(["set", "session:data", ["user_title", "${user?.people?.title}"]]);
      $crisp.push(["set", "session:data", ["user_fullname", "${user?.people?.fullname}"]]);`;
        document.head.appendChild(crisp);
      }
    }
  }
}
