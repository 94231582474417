<div class="create-new-version">
  <p-dropdown
    [options]="dropdownOptions"
    [(ngModel)]="dropdownInput"
    placeholder="Document type"
    class="dropdown-width"
  />
  @if (dropdownInput === VersioningOptions.DRAFT_FROM_FILE)
  {
    <input
      pInputText
      type="text"
      placeholder="Paste G-Drive file's URL here"
      [(ngModel)]="fileUrlInput"
      class="dropdown-width"
    />
  }
  <p-button
    label="Go to my new version draft"
    [disabled]="dropdownInput === VersioningOptions.DRAFT_FROM_FILE && !fileUrlInput"
    (onClick)="createDraft(fileUrlInput)"
  />
</div>
