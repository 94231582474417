import {
  Component,
  Inject,
} from '@angular/core';
import {
  AngularComponentInputs,
  CONFIDENTIALITIES,
} from 'app/types';
@Component({
  selector: 'app-workflow-approval-dialog',
  templateUrl: './workflow-approval-dialog.component.html',
  styleUrl: './workflow-approval-dialog.component.scss'
})
export class WorkflowApprovalDialogComponent {
  readonly confidentialityOptions = CONFIDENTIALITIES.map((confidentiality) => ({ name: confidentiality }));
  
  readonly minDate: Date = new Date(Date.now() + 24 * 60 * 60 * 1000);
  readonly maximumDate: Date = new Date(
    new Date().setFullYear(new Date().getFullYear() + 10),
  );
  confidentialityInput: {name: string} = {name: ''};
  dateInput: Date | null = null;
  approveDocument: Function = () => {};

  constructor(@Inject('OUTPUTS') public outputs: AngularComponentInputs) {
    this.approveDocument = outputs.approveDocument;
  }
}
