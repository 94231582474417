import { Injectable } from '@angular/core';
import { RateFileOptions, RatingResponse } from 'app/types/rating.types';
import { Observable } from 'rxjs';
import { TrinityService } from 'app/services/trinity/trinity.service';

@Injectable({
  providedIn: 'root',
})
export class RatingService {
  private readonly apiEndpoint: string = '/rating';
  constructor(private trinityService: TrinityService) {}

  rateFile(options: RateFileOptions): Observable<RatingResponse> {
    const res = this.trinityService.post<RatingResponse>(
      `${this.apiEndpoint}/${options.file_id}`,
      {
        body: { rating: options.rating },
        authorized: true,
      },
    );

    return res;
  }

  unrateFile(file_id: string): Observable<RatingResponse> {
    const res = this.trinityService.delete<RatingResponse>(
      `${this.apiEndpoint}/${file_id}`,
      {
        authorized: true,
      },
    );

    return res;
  }
}
