@if (isLoading) {
  <div class="loading-container">
    <div><ng-content select="[loading-header]"></ng-content></div>
    <div class="loading-spinner-container">
      <p-progressSpinner ariaLabel="loading" class="spinner" />
    </div>
    <div><ng-content select="[loading-footer]"></ng-content></div>
  </div>
} @else {
  <ng-content></ng-content>
}
