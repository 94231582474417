import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'side-panel-description',
  templateUrl: './side-panel-description.component.html',
  styleUrl: './side-panel-description.component.scss'
})
export class SidePanelDescriptionComponent {
  @Input() description?: string = undefined;
  @Input() loading: boolean = false;
}
