import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DialogState } from 'app/pages/side-panel/side-panel.types';

@Component({
  selector: 'side-panel-ratings',
  templateUrl: './side-panel-ratings.component.html',
  styleUrl: './side-panel-ratings.component.scss'
})
export class SidePanelRatingsComponent {
  @Input() display?: boolean = false;
  @Input() loading: boolean = false;
  @Output() addRatingEvent = new EventEmitter();

  readonly DialogState = DialogState;
}
