import { Component, Input } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgTemplateOutlet } from '@angular/common';
import { SplitterModule } from 'primeng/splitter';

@Component({
  selector: 'app-loading-component',
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
  standalone: true,
  imports: [ProgressSpinnerModule, NgTemplateOutlet, SplitterModule],
})
export class LoadingComponent {
  @Input()
  isLoading: boolean = false;
}
