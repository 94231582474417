@if (!showSetup) {
  <div class="welcome-text">
    <div class="main-heading">Hi there,</div>
    <div class="subheading">
      thank you for choosing Overlayer,<br />
      THE G-Workspace extension to easily mobilize knowledge
    </div>
    <div class="main-heading">
      We'll help you set-up Overlayer in less than 5 minutes
    </div>
    <div class="description">
      So that all your colleagues can leverage your company's internal knowledge
      directly from Google Workspace through our simple and innovative
      extension, incorporating workflows, automatic tagging, collaborative
      features, and more.
    </div>
    <p-button
      label="Let's set things up!"
      severity="contrast"
      class="setup-button"
      (onClick)="showSetupComponent()"
    ></p-button>
  </div>
}

@if (showSetup) {
  <app-setup-onboarding></app-setup-onboarding>
}
