<!-- SETTINGS CONTAINER -->
<div class="settings-container">
  <!-- HEADERS -->
  <div class="settings-header-container">
    <h1>Settings</h1>
    <span>Settings & options for your company</span>
  </div>
  <div class="settings-container">
    <app-loading-component [isLoading]="!settings.client">
      <div loading-header>
        <h2>Loading settings...</h2>
      </div>
      <div class="settings-attributes-container">
        <p
          class="apply-attribute-row"
          pTooltip="Change file share permissions automatically when a confidentiality is set, e.g: public => anyone with the link can view the file."
        >
          Apply file share permissions from confidentiality
          <span>
            <p-inputSwitch
              onLabel="Yes"
              offLabel="No"
              [ngModel]="
                settings.client?.apply_file_permissions_from_confidentiality
              "
              (onChange)="onFilePermissionsConfidentialityChange($event)"
            />
          </span>
        </p>
      </div>
    </app-loading-component>
  </div>
</div>
