export enum GoogleMimeTypes {
  drive = 'drive',
  document = 'application/vnd.google-apps.document',
  spreadsheet = 'application/vnd.google-apps.spreadsheet',
  presentation = 'application/vnd.google-apps.presentation',
  form = 'application/vnd.google-apps.form',
  drawing = 'application/vnd.google-apps.drawing',
  script = 'application/vnd.google-apps.script',
  site = 'application/vnd.google-apps.site',
  folder = 'application/vnd.google-apps.folder',
  shortcut = 'application/vnd.google-apps.drive-sdk',
}

export const KnownMimesToShorten: { [key: string]: string } = {
  'application/vnd.google-apps.document': 'Google document',
  'application/vnd.google-apps.spreadsheet': 'Google sheet',
  'application/vnd.google-apps.presentation': 'Google slide',
  'application/vnd.google-apps.form': 'Google form',
  'application/vnd.google-apps.drawing': 'Google drawing',
  'application/vnd.google-apps.script': 'Google script',
  'application/vnd.google-apps.site': 'Google site',
  'application/vnd.google-apps.folder': 'Google folder',
  'application/vnd.google-apps.drive-sdk': 'Google shortcut',
};

export const DEFAULT_GOOGLE_AVATAR_URI =
  'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg';

export const CONFIDENTIALITIES: string[] = [
  'Public',
  'Internal',
  'Confidential',
];

export const DOC_TYPES: string[] = [
  'Business document',
  'Customer references',
  'Informative document',
  'Financial/Transactional document',
  'HR document',
  'Supplier/Vendor document',
  'Compliance/regulatory document',
  'Policies',
  'Agreement',
];

export enum DocumentType {
  BUSINESS = 'Business document',
  CUSTOMER_REFERENCES = 'Customer references',
  INFORMATIVE = 'Informative document',
  FINANCIAL = 'Financial/Transactional document',
  HR = 'HR document',
  SUPPLIER = 'Supplier/Vendor document',
  COMPLIANCE = 'Compliance/regulatory document',
  POLICIES = 'Policies',
  AGREEMENT = 'Agreement',
}

export const LANGUAGES: string[] = [
  'English',
  'French',
  'Spanish',
  'Italian',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'Finnish',
  'German',
  'Greek',
  'Hungarian',
  'Japanese',
  'Lithuanian',
  'Latvian',
  'Norwegian',
  'Polish',
  'Portuguese',
  'Romanian',
  'Russian',
  'Slovak',
  'Slovenian',
  'Swedish',
];

export enum UnprocessedEntityReason {
  NOT_ENOUGH_PERMISSIONS = 'NOT_ENOUGH_PERMISSIONS',
  NOT_FOUND = 'NOT_FOUND',
  VERIFICATION_ALREADY_REQUESTED = 'VERIFICATION_ALREADY_REQUESTED',
  ALREADY_VERIFIED = 'ALREADY_VERIFIED',
}

export enum GoogleRessourceRole {
  READER = 'reader',
  WRITER = 'writer',
  COMMENTER = 'commenter',
  ORGANIZER = 'organizer',
  FILE_ORGANIZER = 'fileOrganizer',
  OWNER = 'owner',
}
