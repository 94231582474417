import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
} from '@angular/core';
import { DialogState } from 'app/pages/side-panel/side-panel.types';
import { AngularComponent } from 'app/types/angular-component.types';

@Component({
  selector: 'side-panel-dialogs',
  templateUrl: './side-panel-dialogs.component.html',
  styleUrl: './side-panel-dialogs.component.scss'
})
export class SidePanelDialogsComponent {
  @Input() dialog: AngularComponent = null;
  @Input() injector?: Injector = undefined;
  @Input() visible: boolean = false;
  @Input() header: string = '';
  @Input() isLoading: boolean = false;
  @Output() hideDialogCallback = new EventEmitter();

  readonly DialogState = DialogState;
}
