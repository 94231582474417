import {
  Domain,
  People
} from 'app/types';

export enum Role {
  /* Default client role */
  CLIENT = 'client',

  /* Domain admin role (onboarding ...) */
  DOMAIN_ADMIN = 'domain_admin',
  
  /* Overlayer admin role */
  ADMIN = 'admin',

  /* Internal api tokens (pyrone ...) */
  INTERNAL = 'internal',

  /* Dedicated account in customer domain */
  DEDICATED_ACCOUNT = 'dedicated_account',
}

export interface User {
  id: string;
  primary_email: string;
  emails: string[];
  people: People;
  roles: Role[];
  domain: Domain;
  created_at: Date;
  updated_at: Date;
}
