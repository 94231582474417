<!-- BREADCRUMB -->
<div class="breadcrumb-container">
  <p-breadcrumb
    class="max-w-full"
    [model]="todo.breadcrumbPath"
    [home]="todo.breadcrumbIcon"
  >
    <ng-template pTemplate="item" let-item>
      <span class="breadcrumb-path">{{ item.label }}</span>
    </ng-template>
  </p-breadcrumb>
</div>

<!-- CHIPS -->
<div class="chips-container">
  @for (chip of todo.chips | keyvalue; track chip.value.label; let index = $index) {
    <app-chip
      [label]="chip.key"
      [icon]="chip.value.icon"
      [isActive]="chip.value.isActive"
      (mousedown)="updateChipAndFilter(chip.value)"
      [badge]="chip.value.badge"
      [isLoading]="chip.value.isLoading"
    >
    </app-chip>
  }
</div>

<!-- TABLES -->
<div class="tables-container {{ todo.dynamicClass }}">
  <!--@if (todo.filteredOn === ALL) {
    @if (todo.filesToApprove.length !== 0) {
      <div class="table-header-container">
        <h1>To approve</h1>
      </div>
      <app-ng-table [documents]="todo.filesToApprove"></app-ng-table>
    }
    @if (todo.filesToUpdate.length !== 0) {
      <div class="table-header-container">
        <h1>To update</h1>
      </div>
      <app-ng-table
        [documents]="todo.filesToUpdate"
        [canSelect]="false"
      ></app-ng-table>
    }
    @if (todo.filesIRequested.length !== 0) {
      <div class="table-header-container">
        <h1>Approval status</h1>
      </div>
      <app-ng-table
        [documents]="todo.filesIRequested"
        [workflows]="todo.workflows"
        [showApprovers]="true"
        [showLastAction]="true"
        [canSelect]="false"
      ></app-ng-table>
    }
    @if (todo.filesToManage.length !== 0) {
      <div class="table-header-container">
        <h1>Files to manage</h1>
      </div>
      <app-ng-table
        [documents]="todo.filesToManage"
        [canSelect]="true"
      ></app-ng-table>
    }
    } --> @if (
    todo.filteredOn === TO_APPROVE && todo.filesToApprove.length !== 0
  ) {
    <div class="table-header-container">
      <h1>To approve</h1>
    </div>
    <app-ng-table [documents]="todo.filesToApprove"></app-ng-table>
  } @else if (
    todo.filteredOn === TO_UPDATE && todo.filesToUpdate.length !== 0
  ) {
    <div class="table-header-container">
      <h1>To update</h1>
    </div>
    <app-ng-table
      [documents]="todo.filesToUpdate"
      [canSelect]="false"
    ></app-ng-table>
  } @else if (
    todo.filteredOn === I_REQUESTED && todo.filesIRequested.length !== 0
  ) {
    <div class="table-header-container">
      <h1>Approval status</h1>
    </div>
    <app-ng-table
      [documents]="todo.filesIRequested"
      [workflows]="todo.workflows"
      [showApprovers]="true"
      [showLastAction]="true"
      [canSelect]="false"
    ></app-ng-table>
  } @else if (
  todo.filteredOn === MANAGE_LABELS && todo.filesToManage.length !== 0
  ) {
    <div class="table-header-container">
      <h1>Manage labels</h1>
    </div>
    <app-ng-table
      [documents]="todo.filesToManage"
      [canSelect]="true"
    ></app-ng-table>
  } 
  @else {
    <div class="empty-todo-container">
      <h1>No results</h1>
    </div>
  }
</div>

@if (todo.isFooterDisplayed) {
  @if (todo.filteredOn === MANAGE_LABELS) {
    <div class="footer-container">
      <app-footer
        [selectedFiles]="selectedFiles"
        [renewVerification]="true"
      ></app-footer>
    </div>
  } @else  {
    <div class="footer-container">
      <app-footer
        [selectedFiles]="selectedFiles"
        [verification]="true"
        [reject]="true"
      ></app-footer>
    </div>
  }
}
