import { environment } from 'environments/environment';

const apiEndpoint = environment.apiURL;

interface getLoginURIOptions {
  /** Redirect back to the current page after login. */
  redirectBackToCurrentPage?: boolean;
}

function isLoginURI(): boolean {
  return window.location.pathname.slice(1) === 'login';
}

function getRedirectURI(): string {
  const url = new URL(`${window.location.origin}${window.location.pathname}`);
  url.pathname = 'login';
  return url.href;
}

function getLoginURI(options: getLoginURIOptions = {}) {
  const uri = getRedirectURI();
  const data: { [id: string]: string } = {};

  if (options.redirectBackToCurrentPage === true) {
    data['redirect'] = window.location.pathname.slice(1);
  }

  return `${uri}?${new URLSearchParams(data).toString()}`;
}

export { apiEndpoint, getLoginURI, isLoginURI };
