<div class="contributors-container">
  <span class="span-header">Creator & Contributor(s)</span>
  <div class="contributions-container">
    @if (creator) {
      <p-tag
        class="people-tag"
        icon="pi pi-user"
        value="{{ extractNameFromPeople(creator) }}"
      />
    }
    @for (contributor of contributors; track contributor) {
      @if (contributor.fullname || contributor.email) {
        <p-tag
          class="people-tag"
          icon="pi pi-users"
          value="{{ extractNameFromPeople(contributor) }}"
        />
      }
    }
  </div>
</div>
