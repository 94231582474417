<div class="breadcrumb-container">
  <p-breadcrumb class="max-w-full" [model]="path" [home]="home">
    <ng-template pTemplate="item" let-item>
      <a (mousedown)="breadcrumbNavigate(item)" class="clickable-text">{{
        item.label
      }}</a>
    </ng-template>
  </p-breadcrumb>
</div>
<div class="filters-row">
  <p-multiSelect
    [options]="filters.tags.values"
    [(ngModel)]="filters.tags.selected"
    (onChange)="updateFilter('tags', $event)"
    (onClear)="clearFilter('tags')"
    placeholder="Select tags"
    optionLabel="label"
    display="chip"
    class="multi-select"
  />
  <p-multiSelect
    [options]="filters.contributors.values"
    [(ngModel)]="filters.contributors.selected"
    (onChange)="updateFilter('contributors', $event)"
    (onClear)="clearFilter('contributors')"
    placeholder="Select contributors"
    optionLabel="email"
    display="chip"
    class="multi-select"
  >
    <ng-template let-contributor pTemplate="item">
      <div class="multi-select-row">
        <p-avatar
          image="{{ contributor.photo_uri ?? default_avatar_uri }}"
          styleClass="mr-2"
          size="normal"
          shape="circle"
          class="creator-avatar"
        ></p-avatar>
        <div>{{ contributor.email }}</div>
      </div>
    </ng-template>
  </p-multiSelect>
  <p-multiSelect
    [options]="labelOptionsToText(filters.labels.values)"
    [(ngModel)]="filters.labels.selected"
    (onChange)="updateFilter('labels', $event)"
    (onClear)="clearFilter('labels')"
    placeholder="Select labels"
    display="chip"
    class="multi-select"
  />
  <p-multiSelect
    [options]="filters.formats.values"
    [(ngModel)]="filters.formats.selected"
    (onChange)="updateFilter('formats', $event)"
    (onClear)="clearFilter('formats')"
    placeholder="Select formats"
    optionLabel="shortMimetype"
    display="chip"
    class="multi-select"
  >
    <ng-template let-format pTemplate="item">
      <div class="multi-select-row">
        <img
          src="{{ format.iconLink }}"
          alt="avatar"
          class="result-icon"
          class="file-icon"
        />
        <div>{{ format.shortMimetype }}</div>
      </div>
    </ng-template>
  </p-multiSelect>
</div>
<div class="body-content">
  @if (parentNode?.loadingFiles) {
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }" />
  } @else {
    <div [style]="{ height: '6px' }"></div>
  }
</div>
<div
  class="scroll-y {{ displayFooter ? 'reducer' : '' }}"
  (scroll)="onScroll($event)"
>
  <app-ng-table [documents]="displayedFiles"></app-ng-table>
  @if (parentNode) {
    @if (storedFiles.length === 0 && !parentNode.loadingFiles) {
      <div class="empty-folder">
        <p>
          The folder "{{ parentNode.datamart?.name }}" does not contain any documents at
          its top level.
        </p>
      </div>
    }
  } @else if (parsedParentPath.length === 1) {
    <div class="empty-folder">
      <p-progressSpinner ariaLabel="loading" />
    </div>
  } @else {
    <div class="empty-folder">
      <p>Select a drive on the left side panel.</p>
    </div>
  }
</div>
@if (displayFooter) {
  <div class="body-content-footer">
    <app-footer
      [selectedFiles]="selectedFiles"
      [properties]="true"
      [request]="true"
    ></app-footer>
  </div>
}
