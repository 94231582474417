import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  TrinityDeleteParams,
  TrinityGetParams,
  TrinityPostParams,
  TrinityPutParams,
} from './trinity.types';
import { apiEndpoint } from 'app/services/api-settings';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrinityService {
  /** API endpoint. */
  private apiEndpoint: string;

  constructor(private httpClient: HttpClient) {
    this.apiEndpoint = apiEndpoint;

    if (this.apiEndpoint.endsWith('/')) {
      this.apiEndpoint = this.apiEndpoint.slice(0, -1);
    }
  }

  private format_path(path: string): string {
    if (path.length === 0) {
      return '/';
    }
    if (path[0] !== '/') {
      return `/${path}`;
    }
    return path;
  }

  get<ResponseType>(
    path: string,
    options: TrinityGetParams = {},
  ): Observable<ResponseType> {
    path = this.format_path(path);

    return this.httpClient.get<ResponseType>(`${this.apiEndpoint}${path}`, {
      withCredentials: options.authorized,
      params: options.params,
      headers: options.headers,
    });
  }

  post<ResponseType>(
    path: string,
    options: TrinityPostParams = {},
  ): Observable<ResponseType> {
    const body = options.body;
    delete options.body;

    path = this.format_path(path);

    return this.httpClient.post<ResponseType>(
      `${this.apiEndpoint}${path}`,
      body,
      { ...options, withCredentials: options.authorized },
    );
  }

  put<ResponseType>(
    path: string,
    options: TrinityPutParams = {},
  ): Observable<ResponseType> {
    const body = options.body;
    delete options.body;

    path = this.format_path(path);

    return this.httpClient.put<ResponseType>(
      `${this.apiEndpoint}${path}`,
      body,
      { ...options, withCredentials: options.authorized },
    );
  }

  delete<ResponseType>(
    path: string,
    options: TrinityDeleteParams = {},
  ): Observable<ResponseType> {
    path = this.format_path(path);

    return this.httpClient.delete<ResponseType>(`${this.apiEndpoint}${path}`, {
      ...options,
      withCredentials: options.authorized,
    });
  }
}
