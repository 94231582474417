import { User, File } from 'app/types';

export enum WorkflowType {
  FLAG = 'flag',
  VERIFY = 'verify',
  CAMPAIGN = 'campaign',
}

export enum WorkflowState {
  WAITING = 'waiting',
  VALIDATED = 'validated',
  REJECTED = 'rejected',
  NONE = 'none',
}

export interface Workflow {
  id: number;
  file: File;
  requested_by: User;
  related_users: User[];
  type: WorkflowType;
  state: WorkflowState;
  has_exported_pdf: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface WorkflowsResponse {
  workflows: Workflow[];
  next_page_token: string | null;
}

export interface WorkflowResponse {
  /** The workflow. */
  workflow: Workflow;
  /** Whether file has been indexed to AI or not **/
  indexed: boolean;
}
