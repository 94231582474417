import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  private readonly _supportedLanguages: Array<string> = ['en-US', 'fr-FR'];
  private _currentLanguage: string = 'en-US';

  constructor(public translateService: TranslateService) {
    // Get user's browser language
    const browserLanguage = navigator.language;

    // Check if the browser language is supported
    this._currentLanguage = this._supportedLanguages.includes(browserLanguage)
      ? browserLanguage
      : this._currentLanguage;

    // Set the language in the translate service
    this.translateService.setDefaultLang(this._currentLanguage);
    this.translateService.use(this._currentLanguage);
  }

  public init(): void {
    // Set the language in the translate service
    this.translateService.setDefaultLang(this._currentLanguage);
    this.translateService.use(this._currentLanguage);
  }

  get currentLanguage(): string {
    // Return the current language
    return this._currentLanguage;
  }

  set currentLanguage(language: string) {
    // Check if the language is supported
    if (this._supportedLanguages.includes(language)) {
      // Set the language in the translate service
      this._currentLanguage = language;
      this.translateService.use(language);
    }
  }
}
