<textarea
  rows="5"
  pInputTextarea
  [(ngModel)]="flagReason"
  class="flag-textarea"
>
</textarea>
<div class="flag-actions">
  <p-button
    label="Cancel"
    severity="secondary"
    (mousedown)="onCancel()"
  />
  <p-button
    label="Save"
    [disabled]="!flagReason"
    (mousedown)="onFlag(flagReason)"
  />
</div>
