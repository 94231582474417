import { Injectable } from '@angular/core';
import { TrinityService } from 'app/services/trinity/trinity.service';
import { ClientResponse } from 'app/types/client.types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  private readonly endpoint: string = '/client/onboarding';
  constructor(private trinityService: TrinityService) {}

  startOnboarding(clientName: string): Observable<ClientResponse> {
    const res = this.trinityService.post<ClientResponse>(
      this.endpoint + '/start',
      {
        body: {
          name: clientName,
        },
        authorized: true,
      },
    );

    return res;
  }

  createServiceAccount(
    email: string,
    password: string,
  ): Observable<ClientResponse> {
    const res = this.trinityService.post<ClientResponse>(
      this.endpoint + '/dedicated_account ',
      {
        body: {
          email: email,
          password: password,
        },
        authorized: true,
      },
    );

    return res;
  }

  postDriveSelectionForOnboarding(
    driveIds: string[],
  ): Observable<ClientResponse> {
    const res = this.trinityService.post<ClientResponse>(
      this.endpoint + '/drive_selection',
      {
        body: {
          drive_ids: driveIds,
        },
        authorized: true,
      },
    );

    return res;
  }
}
