import {
  Component,
  Inject,
} from '@angular/core';
import { AngularComponentInputs } from 'app/types';

@Component({
  selector: 'app-description-dialog',
  templateUrl: './description-dialog.component.html',
  styleUrl: './description-dialog.component.scss'
})
export class DescriptionDialogComponent {
  description: string = '';
  onCancel: Function = () => {};
  onSave: Function = () => {};
  descriptionInput: string = '';

  constructor(
    @Inject('INPUTS') private inputs: AngularComponentInputs,
    @Inject('OUTPUTS') public outputs: AngularComponentInputs
  ) {
    this.description = inputs.description;
    this.descriptionInput = inputs.description;
    this.onCancel = outputs.cancelDescriptionDialog;
    this.onSave = outputs.saveDescriptionDialog;
  }
}
