@if (loading) {
  <div class="skeleton-description">
    <p-skeleton height="26px" class="skeleton-description" styleClass="mb-2" />
  </div>
} @else {
  @if (description) {
    <div class="description-container">
      <span class="span-header">Description</span>
      <div class="description">
        <span>{{ description }}</span>
      </div>
    </div>
  }
}
