import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TodoComponent } from './pages/todo/todo.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './pages/login/login.component';
import { authGuard } from './guards/auth.guard';
import { FolderContentComponent } from './pages/folder-content/folder-content.component';
import { VerifiedDocsComponent } from './pages/verified-docs/verified-docs.component';
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SidePanelComponent } from './pages/side-panel/side-panel.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { SetupOnboardingComponent } from './components/setup-onboarding/setup-onboarding.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'todo', component: TodoComponent, canActivate: [authGuard] },
  {
    path: 'folder',
    children: [
      {
        path: '**',
        component: FolderContentComponent,
        canActivate: [authGuard],
      },
    ],
  },
  {
    path: 'verified',
    component: VerifiedDocsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'search',
    component: SearchResultsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'side-panel',
    component: SidePanelComponent,
    canActivate: [authGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'onboarding',
    component: OnboardingComponent,
  },
  {
    path: 'onboarding/start',
    component: SetupOnboardingComponent,
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
