import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth/auth.service';
import { GdrivesService } from 'app/services/gdrives/gdrives.service';
import type { TreeNodeExpandEvent, TreeNodeSelectEvent } from 'primeng/tree';
import { NavigationStart, Router } from '@angular/router';
import { ReplaySubject, filter, takeUntil } from 'rxjs';
import { EventManager } from 'app/services/events/events.service';
import {
  GoogleMimeTypes,
  EntityDB,
  PrimeFileNode,
  File,
  AuthorizedGoogleDrive,
} from 'app/types';
import { getParentDrive, isGoogleContainer } from 'app/utils/utils';

@Component({
  selector: 'app-side-bar-navigation',
  templateUrl: './side-bar-navigation.component.html',
  styleUrl: './side-bar-navigation.component.scss',
})
export class SideBarNavigationComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  /** Store the left folders tree (drives and folders only). */
  private folderEntityDB: EntityDB = new EntityDB();

  /** Store the tree of everything. */
  private globalEntityDB: EntityDB = new EntityDB();

  /** Store the content of a drive without a tree. */
  private driveEntityDB: EntityDB = new EntityDB();

  /** Keep the focused node to retrigger loading. */
  private focusedNode?: PrimeFileNode;

  /** Variable used to highlight the selected tab. */
  activeTab?: string;

  driveEntities: PrimeFileNode[] = [];

  loadingDrives: boolean = true;

  entityId: string = '';

  constructor(
    private authService: AuthService,
    private gdrivesService: GdrivesService,
    private router: Router,
  ) {}

  ngOnInit() {
    const currentUri: string = this.router.url;
    this.activeTab = currentUri;

    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((val) => {
        this.activeTab = (val as NavigationStart).url;
      });

    this.populateSideBarDrives();

    EventManager.on('sideBarContainerAdded', ((event: CustomEvent) => {
      const { db }: { db: EntityDB; entities: PrimeFileNode[] } = event.detail;

      this.driveEntities = Object.values(db.getTree());
    }) as EventListener);
    EventManager.on('pathSelected', ((event: CustomEvent) => {
      const node: PrimeFileNode = event.detail;

      this.nodeSelect({ node, originalEvent: event } as TreeNodeSelectEvent);
    }) as EventListener);

    EventManager.on('reachBottom', () => {
      if (this.focusedNode) {
        if (this.focusedNode.mime_type === GoogleMimeTypes.drive) {
          this.populateTopLevelDriveContent(this.focusedNode);
        } else {
          this.populateGlobalNonFolderEntities(this.focusedNode);
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  populateTopLevelDriveContent(driveNode: PrimeFileNode) {
    if (driveNode.mime_type !== GoogleMimeTypes.drive) return;

    let localEntity = this.driveEntityDB.get(driveNode.id);

    if (!localEntity) {
      this.driveEntityDB.add({
        entities: [driveNode],
      });

      localEntity = this.driveEntityDB.get(driveNode.id);

      if (!localEntity) {
        console.error('Local entity not found: ', driveNode);
        return;
      }

      /** Override the completed state that could be inherited from the copy. */
      localEntity.completed = false;
      localEntity.loadingFiles = false;
      localEntity.loadingFolders = false;
    }

    if (localEntity.completed || localEntity.loadingFiles) return;

    localEntity.loadingFiles = true;
    this.entityId = localEntity.id;

    this.gdrivesService
      .searchFiles({
        driveid: localEntity.id,
        search: `mimeType != '${GoogleMimeTypes.folder}' and trashed=false`,
        limit: 500,
        next_page_token: localEntity.next_page_token,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ({ files, next_page_token }) => {
          const offset = localEntity.children.length;
          const nodes: PrimeFileNode[] = files.map(
            (file: File, index: number) => {
              return {
                ...file,
                key: localEntity.key + '-' + (offset + index),
                label: file.datamart?.name,
                icon: 'pi pi-folder',
                last_modified: file.last_modified,
                children: [],
                parent: localEntity,
                completed: true,
                loading: false,
              };
            },
          );

          this.driveEntityDB.add({
            entities: nodes,
            emitEvent: 'sideBarGlobalEntitiesAdded',
          });
          localEntity.next_page_token = next_page_token;
        },
        complete: () => {
          localEntity.loadingFiles = false;

          if (!localEntity.next_page_token) {
            localEntity.completed = true;
          }
        },
        error: () => {
          localEntity.loadingFiles = false;
        },
      });
  }

  populateSideBarDrives(nextPageToken?: string) {
    this.loadingDrives = true;
    this.gdrivesService
      .getUserAuthorizedDrives({
        limit: 50,
        next_page_token: nextPageToken,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ({ drives, next_page_token }) => {
          const offset = this.driveEntities.length;

          drives.forEach(
            async (drive: AuthorizedGoogleDrive, index: number) => {
              const primeDriveElement: PrimeFileNode = {
                ...drive,
                tooltip: drive.name,
                key: `${offset + index}`,
                mime_type: GoogleMimeTypes.drive,
                icon: 'pi pi-server',
                completed: false,
                loadingFiles: false,
                loadingFolders: false,
                children: [],
              };

              this.globalEntityDB.add({ entities: [primeDriveElement] });

              if (!primeDriveElement.hidden) {
                this.folderEntityDB.add({
                  entities: [primeDriveElement],
                  emitEvent: 'sideBarContainerAdded',
                });

                const url = this.router.url;
                const parsedParentPath = url.split('/').filter((item) => item);

                if (parsedParentPath[0] !== 'folder') return;

                parsedParentPath.shift();

                if (parsedParentPath[0] === primeDriveElement.id) {
                  this.populateTopLevelDriveContent(primeDriveElement);
                  const localEntity = this.driveEntityDB.get(
                    primeDriveElement.id,
                  );
                  if (localEntity) {
                    /** Ensure that the route component is built. */
                    setTimeout(() => {
                      EventManager.emit('containerSelected', localEntity);
                    }, 100);
                  }
                }
              }
            },
          );
          nextPageToken = next_page_token;
        },
        complete: () => {
          if (nextPageToken) {
            this.populateSideBarDrives(nextPageToken);
          } else {
            this.loadingDrives = false;
          }
        },
        error: () => {
          this.loadingDrives = false;
        },
      });
  }

  expandSideBarFolder(
    entity: PrimeFileNode,
    depth: number,
    max_depth: number = 1,
  ) {
    if (!isGoogleContainer(entity) || depth >= max_depth) return;

    const localEntity = this.folderEntityDB.get(entity.id);

    if (!localEntity) {
      console.error('Local entity not found: ', entity);
      return;
    }

    if (entity.loadingFolders || localEntity.completed) {
      for (const child of entity.children) {
        this.expandSideBarFolder(child, depth + 1, max_depth);
      }
      return;
    }

    localEntity.loadingFolders = true;

    this.gdrivesService
      .getFolderChildren(entity.id, localEntity.next_page_token)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ({ files, next_page_token }) => {
          const offset = entity.children.length;
          const folders: PrimeFileNode[] = files.map(
            (
              {
                name,
                id,
                mime_type,
                last_modified,
                last_modified_by,
                tags,
                labels,
                datamart,
              }: PrimeFileNode,
              index: number,
            ) => {
              return {
                key: `${entity.key}-${offset + index}`,
                label: datamart?.name,
                name,
                id,
                icon: 'pi pi-folder',
                mime_type,
                last_modified: last_modified,
                last_modified_by,
                children: [],
                parent: entity,
                tags,
                labels,
                datamart,
                loading: false,
                completed: false,
              };
            },
          );

          this.globalEntityDB.add({
            entities: folders,
          });
          this.folderEntityDB.add({
            entities: folders,
          });
          localEntity.next_page_token = next_page_token;
        },
        complete: () => {
          localEntity.loadingFolders = false;

          if (localEntity.next_page_token) {
            this.expandSideBarFolder(entity, depth, max_depth);
          } else {
            localEntity.completed = true;
          }

          for (const child of entity.children) {
            if (isGoogleContainer(child) && !child.completed) {
              this.expandSideBarFolder(child, depth + 1, max_depth);
            }
          }
        },
        error: (err) => {
          console.error(err);
          entity.loadingFolders = false;
        },
      });
  }

  populateGlobalNonFolderEntities(parentNode: PrimeFileNode) {
    if (parentNode.completed || parentNode.loadingFiles) return;

    parentNode.loadingFiles = true;

    this.gdrivesService
      .searchFiles({
        limit: 15,
        search: `"${parentNode.id}" in parents and mimeType != '${GoogleMimeTypes.folder}' and trashed=false`,
        driveid:
          parentNode.mime_type === GoogleMimeTypes.drive
            ? parentNode.id
            : undefined,
        next_page_token: parentNode.next_page_token,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ({ files, next_page_token }) => {
          const offset = parentNode.children.length;
          const nodes: PrimeFileNode[] = files.map(
            (file: File, index: number) => {
              return {
                ...file,
                key: parentNode.key + '-' + (offset + index),
                label: file.datamart?.name,
                icon: 'pi pi-folder',
                last_modified: file.last_modified,
                children: [],
                parent: parentNode,
                completed: true,
                loading: false,
              };
            },
          );

          this.globalEntityDB.add({
            entities: nodes,
            emitEvent:
              parentNode.mime_type === GoogleMimeTypes.folder
                ? 'sideBarGlobalEntitiesAdded'
                : undefined,
          });

          parentNode.next_page_token = next_page_token;
        },
        complete: () => {
          parentNode.loadingFiles = false;
          if (!parentNode.next_page_token) {
            parentNode.completed = true;
          }
        },
        error: () => {
          parentNode.loadingFiles = false;
        },
      });
  }

  nodeSelect(event: TreeNodeSelectEvent) {
    const entity: PrimeFileNode = event.node as PrimeFileNode;

    if (entity.mime_type === GoogleMimeTypes.drive && entity.loadingFolders)
      return;

    if (!event.node.expanded) event.node.expanded = !event.node.expanded;

    const globalEntity = this.globalEntityDB.get(entity.id);
    if (!globalEntity) {
      console.error(
        'Trying to access entity that does not exist in the global entity DB: ',
        entity,
      );
      return;
    }

    if (event.node.expanded) {
      this.focusedNode = globalEntity;
    } else {
      this.focusedNode = undefined;
    }

    /** TODO: be able to navigate no matter the folder. */
    //this.router.navigate([
    //  `/folder/${this.globalEntityDB.path(globalEntity!).join('/')}`,
    //]);

    this.router.navigate([`/folder/${getParentDrive(globalEntity)!.id}`]);

    this.expandSideBarFolder(globalEntity, 0, 2);

    const path: string[] = this.globalEntityDB.path(globalEntity!);

    if (globalEntity.mime_type === GoogleMimeTypes.folder) {
      this.populateGlobalNonFolderEntities(globalEntity);
    } else if (globalEntity.mime_type === GoogleMimeTypes.drive) {
      this.populateTopLevelDriveContent(globalEntity);
    }

    EventManager.emit('clearTableSelection');

    if (globalEntity.mime_type === GoogleMimeTypes.folder) {
      EventManager.emit('containerSelected', globalEntity);
    } else if (globalEntity.mime_type === GoogleMimeTypes.drive) {
      const localEntity = this.driveEntityDB.get(globalEntity.id);
      if (localEntity) {
        /** Ensure that the route component is built. */
        setTimeout(() => {
          EventManager.emit('containerSelected', localEntity);
        }, 2000); // Temporary fix to ensure that the route component is built.
      }
    }

    EventManager.emit('path', {
      path,
      db: this.globalEntityDB,
    });
  }

  onNodeExpand(event: TreeNodeExpandEvent) {
    const entity: PrimeFileNode = event.node as PrimeFileNode;

    if (entity.mime_type === GoogleMimeTypes.drive && entity.loadingFolders)
      return;

    const globalEntity = this.globalEntityDB.get(entity.id);
    if (!globalEntity) {
      console.error(
        'Trying to access entity that does not exist in the global entity DB: ',
        entity,
      );
      return;
    }

    for (const child of entity.children) {
      this.expandSideBarFolder(child, 0, 1);
    }
  }

  logout(): void {
    this.authService.logout();
  }
}
