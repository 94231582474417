import {
  GoogleMimeTypes,
  KnownMimesToShorten,
  File,
  People,
  PrimeFileNode,
  LabelFieldType,
  LabelFieldOptionType,
  LabelType,
  FileLabel,
} from 'app/types';
import mime from 'mime';
import { ToastService } from 'app/services/toast/toast.service';

const GOOGLE_DRIVE_REGEXES = [
  /^.*?:\/\/(?:docs|drive)\.google\.com\/\w+\/d\/([\w\-]+).*/, // eslint-disable-line no-useless-escape
  /^.*?:\/\/(?:docs|drive)\.google\.com\/open\?id=([\w\-]+).*/, // eslint-disable-line no-useless-escape
];

export function isGoogleContainer(node: File): boolean {
  return [GoogleMimeTypes.drive, GoogleMimeTypes.folder].includes(
    node.mime_type as GoogleMimeTypes,
  );
}

export function shortMimeType(mimeType: string): string {
  let short: string | null = KnownMimesToShorten[mimeType];

  if (short) return short;

  short = mime.getExtension(mimeType);

  if (short) return short as string;

  return mimeType;
}

export function pushIfNotFound<T>(
  container: T[],
  entity: T,
  predicate: (value: T, index: number, obj: T[]) => boolean,
) {
  if (container.findIndex(predicate) === -1) container.push(entity);
}

export function getFilePrimaryContributor(file: File): People | undefined {
  return file.datamart?.created_by?.email?.includes('@')
    ? file.datamart.created_by
    : file.last_modified_by;
}

export function getParentDrive(node: PrimeFileNode): File | undefined {
  if (node.mime_type === GoogleMimeTypes.drive) return node;

  if (!node.parent) return undefined;

  return getParentDrive(node.parent);
}

export function getFileContributors(
  file: File,
): { contribs: People[]; count: number; names: string } | undefined {
  const contributors = file.datamart?.contributors;
  const MAX_VISIBLE_CONTRIBUTORS = 3;

  if (
    file.datamart?.created_by &&
    !contributors?.some(
      (contributor) => contributor.email === file.datamart?.created_by?.email,
    )
  ) {
    contributors?.unshift(file.datamart.created_by);
  }

  if (contributors) {
    const firstThreeContributors: People[] = contributors.slice(
      0,
      MAX_VISIBLE_CONTRIBUTORS,
    );
    const remainingContributorsCount: number =
      contributors.length > MAX_VISIBLE_CONTRIBUTORS
        ? contributors.length - MAX_VISIBLE_CONTRIBUTORS
        : 0;
    const remainingContributorsNames: string = contributors
      .slice(MAX_VISIBLE_CONTRIBUTORS)
      .map((contributor) => contributor.name)
      .join(' and ');

    return {
      contribs: firstThreeContributors,
      count: remainingContributorsCount,
      names: remainingContributorsNames,
    };
  }

  return undefined;
}

export function redirectTo(link: string): void {
  window.open(link, '_blank');
}

// clipboard.utils.ts
export function copyToClipboard(
  text: string,
  toastService: ToastService,
): void {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);

  if (toastService) {
    toastService.success({
      detail: 'Password copied to clipboard!',
    });
  }
}

export function isGoogleDriveUri(uri: string): boolean {
  return GOOGLE_DRIVE_REGEXES.some((regex) => regex.test(uri));
}

export function getGoogleDriveFileId(uri: string): string | null {
  for (const regex of GOOGLE_DRIVE_REGEXES) {
    const match = uri.match(regex);

    if (match) return match[1];
  }

  return null;
}

function _isFieldOptionSet(
  file: File,
  fieldType: LabelFieldType,
  optionType?: LabelFieldOptionType,
): boolean | undefined {
  return file.labels?.some(
    (fl) =>
      fl.label.type === LabelType.OVERLAYER &&
      fl.label_field.type === fieldType &&
      (!optionType || fl.selected_options.some((o) => o.type === optionType)),
  );
}

export function isFileVerified(file: File): boolean {
  return !!_isFieldOptionSet(
    file,
    LabelFieldType.STATUS,
    LabelFieldOptionType.STATUS_VERIFIED,
  );
}

export function isFileFlagged(file: File): boolean {
  return !!_isFieldOptionSet(
    file,
    LabelFieldType.NEEDS_UPDATE,
    LabelFieldOptionType.NEEDS_UPDATE_FLAGGED,
  );
}

export function fileHasAnyOfThisLabels(
  file: File,
  optionsTypes?: LabelFieldOptionType[],
): boolean | undefined {
  return file.labels?.some((fl) =>
    optionsTypes?.some((optionType) =>
      fl.selected_options.some((o) => o.type === optionType),
    ),
  );
}

export function hasAnyOfThisLabels(
  labels?: FileLabel[],
  labelsOptions?: LabelFieldOptionType[]
): boolean {
  return !!labels?.some((fileLabel) =>
    labelsOptions?.some((labelOption) =>
      fileLabel.label.type === LabelType.OVERLAYER &&
      fileLabel.selected_options.some((o) => o.type === labelOption),
    ),
  );
}

const mapFieldOptionTypeToText: Map<LabelFieldOptionType, string> = new Map([
  [LabelFieldOptionType.STATUS_VERIFIED, 'Verified'],
  [LabelFieldOptionType.VERIFICATION_PROCESS_APPROVED, 'Approved'],
  [LabelFieldOptionType.VERIFICATION_PROCESS_PENDING, 'Pending'],
  [LabelFieldOptionType.VERIFICATION_PROCESS_REJECTED, 'Rejected'],
  [LabelFieldOptionType.STATUS_VERIFICATION_OUTDATED, 'Outdated'],
  [LabelFieldOptionType.NEEDS_UPDATE_FLAGGED, 'Flagged'],
  [LabelFieldOptionType.STATUS_HAS_BEEN_EDITED, 'Verified (Edited)'],
  [LabelFieldOptionType.FILE_CONFIDENTIALITY_PUBLIC, 'Public'],
  [LabelFieldOptionType.FILE_CONFIDENTIALITY_INTERNAL, 'Internal'],
  [LabelFieldOptionType.FILE_CONFIDENTIALITY_CONFIDENTIAL, 'Confidential'],
]);

const mapFieldOptionTextToType: Map<string, LabelFieldOptionType> = new Map(
  [...mapFieldOptionTypeToText].map(([key, value]) => [value, key]),
);

export function labelsFieldOptionTypeToText(
  options: LabelFieldOptionType[],
): string[] {
  return options.map((option) => mapFieldOptionTypeToText.get(option) || '');
}

export function textToLabelsFieldOptionType(
  texts: string[],
): LabelFieldOptionType[] {
  return texts.map(
    (text) => mapFieldOptionTextToType.get(text) || LabelFieldOptionType.CUSTOM,
  );
}

export function openURLInNewTab(url: string) {
  window.open(url, '_blank')?.focus();
}
