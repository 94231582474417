<div class="apply-rating">
  <p-dropdown
    [options]="confidentialityOptions"
    [(ngModel)]="confidentialityInput"
    optionLabel="name"
    placeholder="Confidentiality"
    class="dropdown-width"
  />
  <div class="expiration-date">
    <p-calendar
      [minDate]="minDate"
      [maxDate]="maximumDate"
      [(ngModel)]="dateInput"
      dateFormat="dd / mm / yy"
      [readonlyInput]="true"
      inputId="expiration_date"
      placeholder="Expiration date (optional)"
    />
  </div>
  <p-button
    label="Apply & Get Verified label"
    [disabled]="!confidentialityInput"
    (mousedown)="approveDocument(confidentialityInput.name, dateInput)"
  />
</div>
